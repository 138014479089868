import React, { Fragment,useState, useEffect, useContext } from 'react'
import {useLocation} from 'react-router-dom';

import i18next from "i18next";
import cookies from "js-cookie";
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";



export default function PrivacyPolicy() {

  function PREL(){
    return(
    <Fragment>
  <div className="container section sectionp">
     <h1>Πολιτική Απορρήτου</h1>
     <div>
     <strong>Ποια προσωπικά μου δεδομένα συλλέγονται και πώς;</strong><br/>
  <p>
  Τα προσωπικά δεδομένα τα οποία συλλέγει και επεξεργάζεται ο Δήμος Φολεγάνδρου είναι μόνο αυτά που είναι απαραίτητα για τον εκάστοτε συγκεκριμένο και σαφώς ορισμένο σκοπό και τη συγκεκριμένη νομική βάση.
  
  Στο πλαίσιο αυτό, η επεξεργασία που λαμβάνει χώρα αφορά τα προσωπικά δεδομένα που παρέχετε στον Δήμο Φολεγάνδρου σε πραγματικό διαδραστικό χρόνο, όταν χρησιμοποιείτε τις υπηρεσίες μας, τους επίσημους διαδικτυακούς μας τόπους, για παράδειγμα κάνοντας εγγραφή, όπως:
  <ul className="ulp">
     <li>Όνομα</li>
     <li>Επώνυμο</li>
     <li>E-mail</li>
  </ul>
  Σε όσες από τις περιπτώσεις η επεξεργασία θεμελιώνεται στη λήψη συγκατάθεσης, ο Δήμος Φολεγάνδρου ακολουθεί τις προβλεπόμενες από το Νόμο διαδικασίες για τη λήψη αυτής.
  
  </p><p>
  
  <strong>Για ποιους σκοπούς γίνεται η επεξεργασία και ποια η νομική της βάση;</strong><br/>
  
  Τα προσωπικά δεδομένα συλλέγονται σύμφωνα με τον ΓΚΠΔ και την ισχύουσα νομοθεσία, είτε με την έναρξη της σχέσης σας με τον Οργανισμό είτε και μεταγενέστερα και αποτελούν αντικείμενο επεξεργασίας με νομική βάση:
  
  Τη συγκατάθεσή σας, όταν απαιτείται
  
  Την εκτέλεση μιας μεταξύ μας σύμβασης
  
  Την εκπλήρωση των νομικών μας υποχρεώσεων
  
  Τη διαφύλαξη ζωτικού συμφέροντος
  
  Την εκπλήρωση καθήκοντος για το δημόσιο συμφέρον
  
  Τη διαφύλαξη έννομου συμφέροντός μας ή τρίτου
  
  Ο Δήμος Φολεγάνδρου μπορεί να επεξεργαστεί τα προσωπικά σας δεδομένα, για τους ακόλουθους σκοπούς ανά νομική βάση:
  
  1. Με τη συγκατάθεσή σας
  
  Μεταβίβαση προσωπικών δεδομένων σε τρίτους
  
  Αποστολή ενημερώσεων
  
  2. Για την εκπλήρωση της συμβατικής υποχρέωσης σε περίπτωση σύναψης σύμβασης με τον Δήμος Φολεγάνδρου ή κατά την προσυμβατική διαδικασία
  
  Για την επικοινωνία κατά την προσυμβατική διαδικασία ή κατά την εκτέλεση της σύμβασης
  
  3. Για την εκπλήρωση των νομικών μας υποχρεώσεων
  
  Για την εκπλήρωση των υποχρεώσεών μας σύμφωνα με την ισχύουσα νομοθεσία έναντι δημοσίων αρχών και φορέων (Εποπτικές, Ανεξάρτητες, Αστυνομικές, Δικαστικές).
  
  4. Για τη διαφύλαξη ζωτικού συμφέροντος σας ή τρίτου
  
  Σε περίπτωση που κινδυνεύει η ζωή σας ή η ζωή τρίτου
  
  5. Για λόγους έννομου συμφέροντός μας, ως:
  
  Για την ανάπτυξη και βελτίωση των παρεχόμενων υπηρεσιών μας μέσα από τις δραστηριότητες και τα ενδιαφέροντά σας.
  
  Για τη διαχείριση των παραπόνων σας.
  
  Για την προστασία και ασφάλεια των συστημάτων πληροφορικής.
  
  </p><p>
  
  <strong>Ποιοι είναι οι αποδέκτες των προσωπικών μου δεδομένων;</strong><br/>
  
  Πρόσβαση στα προσωπικά σας δεδομένα έχει το προσωπικό του Δήμου Φολεγάνδρου, στα πλαίσια της εκτέλεσης των καθηκόντων που τους ανέθεσε ο Δήμος Φολεγάνδρου ως υπεύθυνος επεξεργασίας.
  
  Ο Δήμος Φολεγάνδρου είναι υποχρεωμένος ή δικαιούται να κοινοποιεί τα προσωπικά σας δεδομένα σε διάφορους τρίτους αποδέκτες όπως:
  
  Δημόσιους φορείς
  
  Ανεξάρτητες αρχές
  
  υπό τον όρο της τήρησης σε κάθε περίπτωση του απορρήτου και του καθήκοντος εμπιστευτικότητας και εχεμύθειας.
  
  </p><p>
  
  <strong>Πόσο χρόνο διατηρούνται τα δεδομένα μου;</strong><br/>
  
  Ο Δήμος Φολεγάνδρου διατηρεί τα προσωπικά σας δεδομένα μόνον για το διάστημα που απαιτείται για τους σκοπούς της επεξεργασίας. Ωστόσο οφείλει να διατηρεί τα προσωπικά δεδομένα και για χρονικό διάστημα που καθορίζεται από το ισχύον νομικό και κανονιστικό πλαίσιο.
  
  </p><p>
  
  <strong>Τι είναι τα cookies και γιατί τα συλλέγει ο Δήμος Φολεγάνδρου;</strong><br/>
  
  Για να διασφαλίσουμε ότι ο ιστότοπός μας λειτουργεί σωστά, ενδέχεται μερικές φορές να αποθηκευτεί ένα μικρό κομμάτι δεδομένων που είναι γνωστό ως cookie στον υπολογιστή ή την κινητή συσκευή σας κατά την επίσκεψη του ιστότοπού μας. Ένα cookie είναι ένα αρχείο κειμένου που αποθηκεύεται από έναν διακομιστή ιστού σε έναν υπολογιστή ή μια κινητή συσκευή. Το περιεχόμενο ενός cookie μπορεί να ανακτηθεί ή να διαβαστεί μόνο από το διακομιστή που δημιουργεί το cookie. Το κείμενο σε ένα cookie αποτελείται συχνά από αναγνωριστικά, ονόματα τοποθεσιών και ορισμένους αριθμούς και χαρακτήρες. Τα cookies είναι μοναδικά για τα προγράμματα περιήγησης ή τις κινητές εφαρμογές που χρησιμοποιείτε και επιτρέπουν στους ιστότοπους να αποθηκεύουν δεδομένα όπως οι προτιμήσεις σας.
  
  </p><p>
  
  <strong>Πως προστατεύονται τα δεδομένα μου;</strong><br/>
  
  Στον Δήμο Φολεγάνδρου εργαζόμαστε καθημερινά για να εξασφαλίσουμε ότι τα προσωπικά δεδομένα που λαμβάνουμε:
  
  Υποβάλλονται σε σύννομη και θεμιτή επεξεργασία με διαφανή τρόπο σε σχέση με το υποκείμενο των δεδομένων.
  
  Συλλέγονται αποκλειστικά για συγκεκριμένους και νόμιμους σκοπούς.
  
  Είναι επαρκή, σχετίζονται με το σκοπό για τον οποίο τα συλλέγουμε και περιορίζονται στα απαραίτητα.
  
  Είναι ακριβή και ενημερωμένα.
  
  Διατηρούνται αποκλειστικά εντός του καθορισμένου χρονικού πλαισίου και όχι περισσότερο.
  
  Τίθενται σε επεξεργασία με τέτοιο τρόπο ώστε να εξασφαλίζεται η απαραίτητη προστασία τους.
  
  </p><p>
  
  <strong>Ποια είναι τα δικαιώματά μου;</strong><br/></p>
  
  Ο Δήμος Φολεγάνδρου δεν επεξεργάζεται με αυτοματοποιημένο τρόπο και δεν δημιουργεί προφίλ για κανένα φυσικό πρόσωπο του οποίου διατηρεί τα προσωπικά δεδομένα
  
  Το υποκείμενο των δεδομένων έχει τα ακόλουθα δικαιώματα:
  
  <p><br/><strong>Το δικαίωμα πρόσβασης</strong><br/></p>
  
  Μπορείτε ανά πάσα στιγμή να ενημερωθείτε από εμάς για τα προσωπικά σας δεδομένα που διατηρούμε, και να έχετε πρόσβαση σε αυτά.
  
  <p><br/><strong>Το δικαίωμα στη διόρθωση δεδομένων</strong><br/></p>
  
  Έχετε τη δυνατότητα να απευθυνθείτε σε εμάς για να διορθώσουμε τα δεδομένα που είναι ανακριβή ή ελλιπή.
  
  <p><br/><strong>Το «δικαίωμα στη λήθη»</strong><br/></p>
  
  Εφόσον δεν μας υποχρεώνει κάποιο νομοθετικό πλαίσιο να διατηρήσουμε τα δεδομένα που διατηρούμε και σχετίζονται με εσάς, μπορείτε να μας ζητήσετε να τα διαγράψουμε.
  
  <p><br/><strong>Το δικαίωμα στη φορητότητα των δεδομένων</strong><br/></p>
  
  Μπορείτε να μας ζητήσετε να διαβιβάσουμε τα δεδομένα σας σε άλλο δημόσιο ή ιδιωτικό φορέα.
  
  <p><br/><strong>Το δικαίωμα στην εναντίωση και στον περιορισμό της επεξεργασίας</strong><br/></p>
  
  Σε περίπτωση που διαφωνείτε με τον τρόπο που επεξεργαζόμαστε τα προσωπικά σας δεδομένα μπορείτε να αιτηθείτε τη διακοπή ή τον περιορισμό της επεξεργασίας.
  
  <p><br/><strong>Το δικαίωμα άρσης συγκατάθεσης</strong><br/></p><p>
  
  Έχετε το δικαίωμα να αποσύρετε τη συγκατάθεσή σας στην επεξεργασία των δεδομένων σας ανά πάσα στιγμή.
  
  Ο Δήμος Φολεγάνδρου θα καταβάλει κάθε προσπάθεια να απαντήσει στο αίτημα που θα υποβάλλετε χωρίς καθυστέρηση και σε κάθε περίπτωση εντός μηνός από την παραλαβή του. Η εν λόγω προθεσμία παρατείνεται για δύο (2) ακόμα μήνες, εφόσον είναι απαραίτητο λαμβάνοντας υπόψη την πολυπλοκότητα του αιτήματος και το πλήθος των αιτημάτων. Ο Δήμος Φολεγάνδρου θα σας ενημερώσει για την εν λόγω παράταση εντός μηνός από την παραλαβή του αιτήματος, καθώς και για τους λόγους της καθυστέρησης. Εάν έχετε υποβάλει το αίτημα με ηλεκτρονικά μέσα, η ενημέρωση παρέχεται, εάν είναι δυνατόν, με ηλεκτρονικά μέσα, εκτός εάν εσείς ζητήσετε κάτι διαφορετικό.
  
  Εφόσον ο Δήμος Φολεγάνδρου ικανοποιήσει αίτημά σας α) για περιορισμό επεξεργασίας των δεδομένων σας ή β) για άρνηση επεξεργασίας των δεδομένων σας ή γ) για διαγραφή των δεδομένων σας από τα αρχεία του οργανισμού και εφόσον αυτά είναι απαραίτητα για την κατάρτιση ή τη συνέχιση και εκτέλεση σύμβασης, τότε αυτό συνεπάγεται αυτοδίκαια είτε την καταγγελία από εσάς της αντίστοιχης σύμβασης ή τη μη δυνατότητα επεξεργασίας του σχετικού σας αιτήματος.
  
  </p><p>
  
  <strong>Ανάκληση της συγκατάθεσης</strong><br/>
  
  Έχετε το δικαίωμα να ανακαλέσετε τη συγκατάθεσή σας ανά πάσα στιγμή. Η ανάκληση της συγκατάθεσης σας δε θίγει τη νομιμότητα της επεξεργασίας που βασίστηκε σε αυτή και έγινε πριν της ανάκλησής της.
  
  </p><p>
  
  <strong>Cookies</strong><br/>
  
  Για να διασφαλίσουμε ότι ο ιστότοπός μας και η εφαρμογή για κινητές συσκευές λειτουργούν σωστά, ενδέχεται μερικές φορές να τοποθετήσουμε ένα μικρό κομμάτι δεδομένων που είναι γνωστό ως cookie στον υπολογιστή ή την κινητή συσκευή σας. Ένα cookie είναι ένα αρχείο κειμένου που αποθηκεύεται από έναν διακομιστή ιστού σε έναν υπολογιστή ή μια κινητή συσκευή. Το περιεχόμενο ενός cookie μπορεί να ανακτηθεί ή να διαβαστεί μόνο από το διακομιστή που δημιουργεί το cookie. Το κείμενο σε ένα cookie αποτελείται συχνά από αναγνωριστικά, ονόματα τοποθεσιών και ορισμένους αριθμούς και χαρακτήρες. Τα cookies είναι μοναδικά για τα προγράμματα περιήγησης ή τις κινητές εφαρμογές που χρησιμοποιείτε και επιτρέπουν στους ιστότοπους να αποθηκεύουν δεδομένα όπως οι προτιμήσεις σας.
  
  </p><p>
  
  <strong>Υπεύθυνος Προστασίας Δεδομένων</strong><br/>
  
  Σε περίπτωση που έχετε ερωτήσεις σχετικά με την προστασία των δεδομένων σας και τα δικαιώματά σας, μπορείτε να στείλετε email στο dfolegandrou@5109.syzefxis.gov.gr ή να απευθυνθείτε εγγράφως στην ταχυδρομική διεύθυνση: ΧΩΡΑ ΦΟΛΕΓΑΝΔΡΟΣ ΚΥΚΛΑΔΕΣ, 84 011 και θα απαντήσουμε σε αυτές το συντομότερο δυνατόν και όχι αργότερα από ένα μήνα.
  
  </p><p>
  
  <strong>Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα</strong><br/>
  
  Έχετε δικαίωμα να υποβάλλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (<a href="www.dpa.gr" target="_blank" rel="noopener">www.dpa.gr</a> ), η οποία είναι η αρμόδια εποπτική αρχή για την προστασία των θεμελιωδών δικαιωμάτων και ελευθεριών των φυσικών προσώπων έναντι της επεξεργασίας των προσωπικών τους δεδομένων του, εφόσον θεωρείτε ότι προσβάλλονται τα δικαιώματά σας με οποιοδήποτε τρόπο.
  
  Σας παροτρύνουμε να δείτε την Πολιτική για την Ιδιωτικότητα και την Προστασία των Προσωπικών Δεδομένων του Ιδρύματος στον σύνδεσμο Πολιτική Ιδιωτικότητας και προστασίας δεδομένων
    </p> </div>
      </div>
      </Fragment>
  )}

  function PREN(){
    return(
    <Fragment>
  <div className="container section sectionp">
     <h1>Privacy Policy</h1>
     <div>
     <strong>Which personal data are collected and how?

</strong><br/>
  <p>
  The personal data that Municipality of Folegandros collects and processes are only those that are considered necessary for any specific and clearly defined goal and the specific legal basis.

Within this scope, the processing that takes place regards personal data that you provide in Municipality of Folegandros in real interactive time, when you use our services, our official web sites, for instance a request, or you are a worker such as:  <ul className="ulp">
     <li>Name</li>
     <li>Surname</li>
     <li>E-mail</li>
  </ul>
  In any of the cases the processing is founded in the receipt of consent, Municipality of Folegandros abides by the provided by Law proceedings for the receipt hereof.  
  </p><p>
  
  <strong>For which purposes is the processing effected and which is its legal basis?

</strong><br/>
  
The personal data are collected according to GRDP and the legislation in force, either by the commencement of your relation with Municipality of Folegandros or after that and they constitute an object of processing by a legal basis:

Your consent, when required

The execution of an agreement by and between us

The fulfillment of our legal obligations

The safeguarding of vital interest

The fulfillment of duty for the public interest

The safeguarding of our legal interest or of a third party

Municipality of Folegandros can process your personal data, for the following purposes per legal basis:

1. With your consent

Transfer of personal data to third parties

Dispatching of updates

2. For the fulfillment of the contractual obligation in the event of entering into an agreement with the Municipality of Folegandros or according to the pre-contractual process

For the communication upon the pre-contractual process or upon the execution of the agreement

3. For the fulfillment of our legal obligations

For the fulfillment of our obligations according to the legislation into force against public authorities and entities (Supervisory, Independent, Police, Judicial Authorities).

4. For the safeguarding of your vital interest or of a third party

In the event that your life or the life of a third party is in danger

5. For reasons of our legal interest, as:

For the development and the improvement of our provided services through your activities and your interests.

For the handling of your complaints.

For the protection and the safety of IT systems.
  
  </p><p>
  
  <strong>Who are the receivers of my personal data?</strong><br/>
  
  The staff of Municipality of Folegandros has access to your personal data, within the scope of execution of the duties assigned by Municipality of Folegandros as responsible for processing.

Municipality of Folegandros is obliged or is entitled to communicate your personal data to various third party receivers such as:

Public entities

Independent authorities

under the provision of the compliance in any event of the classified and the confidentiality and secrecy duty.  
  </p><p>
  
  <strong>How long are my data preserved?</strong><br/>
  
  Municipality of Folegandros keeps your personal data only for the period required for the purposes of processing. However, it has to preserve the personal data for a time period defined by the legal and regulatory framework in force.

  
  </p><p>
  
  <strong>What are the cookies and why does Municipality of Folegandros selects them?

</strong><br/>
  
In order to ensure that our website operates properly, sometimes it is possible to store a small part of data that is known as cookie in your computer or your mobile device during the visit in our website. A cookie is a text file that is stored by a web server to a computer or a mobile device. The content of a cookie can be retrieved or read only by the server that creates the cookie. The text in a cookie often consists of identifiers, website names and certain numbers and characters. The cookies are unique for the browser or the mobile applications that you use and they allow to websites to store data as your preferences.  
  </p><p>
  
  <strong>How my data are protected?

</strong><br/>
  
In Municipality of Folegandros we work on a daily basis in order to ensure that the personal data that we receive:

Are subject to a legal and legitimate processing in a transparent manner related to the subject of data.

Data are collected exclusively for specific and legal purposes.

Data are sufficient, they are related to the purpose for which are collected and they are limited to the necessary data.

They are accurate and updated.

They are maintained exclusively within the defined time framework and not for a longer period.

They are subject to processing in such a way in order to ensure their necessary protection.  
  </p><p>
  
  <br/><strong>What are my rights?

</strong><br/></p>
  
Municipality of Folegandros processes automatically and does not create a profile for any natural person for who it maintains the personal data.

The subject of the data has the following rights:  
  <p><br/><strong>The right to access

</strong><br/></p>
  
At any time, you can be informed by us for your personal data that we maintain, and have access to them.

  
  <p><br/><strong>The right to data correction

</strong><br/></p>
  
You have the potential to address to us in order to correct the data that are inaccurate or insufficient.

  
  <p><br/><strong>The "right to oblivion"

</strong><br/></p>
  
Provided that we are not obliged by a legal framework to maintain the data that we keep and are related to you, you can ask us to delete them.


  <p><br/><strong>The right to data transferability

</strong><br/></p>
  
You can ask us to transfer your data to another public or private entity.
  
In the event that you disagree with the way that we process your personal data you can request the stop or the limitation of processing.
<br />
<p>
<br />
  <strong>The right to lift consent

</strong><br/>
  
You have the right to withdraw your consent in the processing of your data at any time.

Municipality of Folegandros will make any endeavor to respond to the request that you will submit without delay and in any event within a month from its receipt. The above deadline is extended for two (2) more months, provided that this is necessary considering the complexity of the request and the multitude of requests. Municipality of Folegandros will inform you of the above time extension within a month from the receipt of the request, as well as of the reasons of the delay. If you have submitted the request by electronic means, the update is provided, if possible, by electronic means, unless it is otherwise asked by you.

Provided that Municipality of Folegandros satisfies your request a) for limitation of processing of your data or b) for refusal of processing your data or c) for deletion of your data from the files of Municipality of Folegandros and provided that they are necessary for the entering into or the continuation and execution of the agreement, then this entails by right either the termination from your part of the corresponding agreement or the non-potential of processing of your relevant request.
  
  </p><p>
  <strong>Revocation of consent



</strong><br/>
  
You have the right to revoke your consent at any time. The revocation of your consent does not affect the legitimate nature of the processing based on it and was effected prior to its revocation.


  </p><p>
  <strong>Cookies</strong><br/>
  
  In order to make sure our site is working properly, we may sometimes put a small piece of data known as cookie on your computer or mobile device. A cookie is a text file that is stored by a web server on a computer or a mobile device. The content of a cookie can only be retrieved or read by the cookie server. Text in a cookie often consists of identifiers, site names and some numbers and characters. Cookies are unique to browsers or mobile apps you use, and allow sites to store data, such as your preferences.

  
  </p><p>
  
  <strong>Head of Data Protection

</strong><br/>
  
In the event that you have questions about your data protection and your rights, you can send an email to  dfolegandrou@5109.syzefxis.gov.gr or to address in writing to the following postal address: CHORA FOLEGANDROS KYKLADES, 84 011, Greece and we will reply to them the soonest possible and not later than a month.  
  </p><p>
  
  <strong>Personal Data Protection Authority</strong><br/>
  
  You have the right to submit a complaint to the Personal Data Protection Authority (www.dpa.gr ), that is the competent supervisory authority for the protection of the essential rights and liberties of the natural persons against the processing of their personal data, provided that you consider that your rights are affected in any way whatsoever.

We urge you to see the Policy on Personal Data Privacy and Protection of the GNTO in the following link Policy on Data Protection and Privacy   </p> </div>
      </div>
      </Fragment>
  )}

  function PRFR(){
    return(
      <Fragment>
    <div className="container section sectionp">
       <h1>Politique de confidentialité</h1>
       <br/><b>Lesquelles de mes données personnelles sont-elles collectées et comment ?</b><br/><br/>

<span >Les données personnelles ne sont collectées et traitées par la Municipalité de Folégandros que dans la mesure où c'est indispensable pour chaque objectif particulier clairement défini et selon une base juridique spécifique. Dans ce cadre-là, le traitement effectué concerne les données personnelles que vous fournissez à la Municipalité de Folégandros en temps réel interactif, lorsque vous utilisez nos services, nos sites officiels, notamment lors de votre inscription, par exemple:</span>
<ul className="ulp">
 	<li  aria-level="1"><span >Prénom </span></li>
 	<li  aria-level="1"><span >Nom</span></li>
 	<li  aria-level="1"><span >E-mail</span></li>
</ul>
<span >Dans tous les cas où le traitement de données implique l'obtention du consentement, la Municipalité de Folégandros suit les procédures prévues par la loi pour l'obtenir.</span>

<br/>

<br/><b>Quel sont l'objectif et la base juridique du traitement de données ?</b><br/><br/>

<span >Les données personnelles sont collectées conformément au RGPD et à la législation en vigueur, soit dès le début de votre relation avec l'Organisme, soit ultérieurement, et font l'objet de traitement sur une des bases juridiques suivantes : </span>

<span >Votre consentement, si nécessaire. </span>

<span >Exécution d'un contrat entre nous </span>

<span >Accomplissement de nos obligations juridiques</span>

<span >Sauvegarde d'un intérêt vital</span>

<span >Accomplissement d'un devoir d'intérêt public </span>

<span >Sauvegarde de notre intérêt légitime ou de celui d'un tiers</span>

<span >La Municipalité de Folégandros peut traiter vos données personnelles pour les objectifs indiqués ci-dessous et sur les bases juridiques suivantes :</span>

<br/>
<ol className="ulp">
 	<li><span > 1. Avec votre consentement:</span></li>
</ol>
<span >Transmission des données personnelles à des tiers</span>

<span >Envoi des mises à jour </span>

<br/>
<ol className="ulp" start="2">
 	<li><span > 2. Pour remplir l'obligation contractuelle en cas de conclusion d'un contrat avec la Municipalité de Folégandros ou selon la procédure précontractuelle </span></li>
</ol>
<span >Communication selon la procédure précontractuelle ou pendant l'exécution du contrat </span>

<br/>
<ol className="ulp" start="3">
 	<li><span > 3. Pour remplir nos obligations juridiques </span></li>
</ol>
<span >Accomplissement de nos obligations conformément à la législation en vigueur, à l'égard des autorités et organismes publics (de contrôle, indépendantes, de police, de justice).</span>

<br/>
<ol className="ulp" start="4">
 	<li><span > 4. Pour sauvegarder votre intérêt légitime ou celui d'un tiers</span></li>
</ol>
<span >En cas de danger pour votre vie ou celle d'un tiers</span>

<br/>
<ol className="ulp" start="5">
 	<li><span > 5. Pour des raisons de notre intérêt légitime:</span></li>
</ol>
<span >Développement et optimisation de nos services, fournis dans le cadre de vos activités et en fonction de vos intérêts.</span>

<span >Gestion de vos plaintes.</span>

<span >Protection et sécurité des systèmes informatiques.</span>

<br/>

<br/><b>Qui est-ce qui a l'accès à mes données personnelles ?</b><br/><br/>

<span >L'accès à vos données personnelles est accordé au personnel de la Municipalité de Folégandros, dans le cadre de l'exécution des devoirs qui lui sont confiés par la Municipalité de Folégandros en tant que responsable de traitement.</span>

<br/>

<span >La Municipalité de Folégandros peut être dans l'obligation ou avoir le droit de communiquer vos données personnelles à de divers destinataires tiers, par exemple:</span>

<span >Organismes publics</span>

<span >Autorités indépendantes</span>

<span >et ce, dans tous les cas, à condition que le secret personnel et le devoir de confidentialité et de discrétion soient respectés.</span>

<br/>

<br/><b>Combien de temps mes données sont-elles conservées ?</b><br/><br/>

<span >La Municipalité de Folégandros ne conserve vos données personnelles que pendant le temps requis par les objectifs du traitement. Elle est pourtant aussi obligée de conserver les données personnelles pendant le temps défini par le cadre juridique et règlementaire en vigueur.</span>

<br/>

<br/><b>Que sont les cookies et pourquoi la Municipalité de Folégandros les collecte ?</b><br/><br/>

<span >Pour garantir le bon fonctionnement de notre site, nous pouvons parfois sauvegarder un petit enregistrement des données, appelé "cookie", sur votre ordinateur ou sur votre appareil mobile pendant la visite de notre site. Un cookie est un fichier texte stocké par un serveur Web sur un ordinateur ou sur un appareil mobile. Le contenu d'un cookie ne peut être récupéré ou lu que par le serveur qui avait créé ce cookie. Le texte d'un fichier cookie se compose souvent d'identifiants, de noms de lieux et de certains chiffres et d'autres caractères. Les cookies sont uniques pour chaque logiciel de navigation et pour chaque application mobile utilisée par vous, et permet aux sites de remémorer vos données, par exemple, vos préférences.</span>

<br/>

<br/><b>Comment mes données sont-elles protégées?</b><br/><br/>

<span >La Municipalité de Folégandros prend quotidiennement soin d'assurer le respect des principes suivants, en ce qui concerne les données personnelles:</span>

<span >Traitement de données légal et légitime, de façon transparente pour la personne concernée par les données.</span>

<span >Collecte exclusivement pour des objectifs spécifiquement définis  et légaux.</span>

<span >Traitement de données suffisant, approprié à l'objectif pour lequel elles sont collectées, et limité au strictement nécessaire.</span>

<span >Exactitude de données et leur mise à jour régulière.</span>

<span >Conservation exclusivement pour le délai prédéterminé, sans jamais le dépasser.</span>

<span >Traitement de données de façon à pouvoir assurer leur protection nécessaire.</span>

<br/>

<br/><b>Quels sont mes droits?</b><br/><br/>

<span >La Municipalité de Folégandros ne procède à aucun traitement automatisé des données et ne crée pas de profil pour aucune personne physique dont elle conserve les données personnelles.</span>

<br/>

<span >La personne concernée par les données a les droits suivants:</span>

<br/>

<br/><b>Droit d'accès</b><br/><br/>

<span >Vous pouvez à tout moment recevoir des mises à jour de notre part en ce qui concerne vos données personnelles conservées par nous et d'y avoir l'accès.</span>

<br/>

<br/><b>Droit de corriger les données</b><br/><br/>

<span >Vous avez la possibilité de nous contacter pour corriger les données inexactes ou incomplètes.</span>

<br/>

<br/><b>Droit à «l'effacement»</b><br/><br/>

<span >Dans la mesure où aucun cadre législatif ne nous oblige de conserver les données en notre disposition qui vous concernent, vous pouvez nous demander de les effacer.</span>

<br/>

<br/><b>Droit à la portabilité des données</b><br/><br/>

<span >Vous pouvez nous demander de transmettre vos données à un autre organisme public ou privé.</span>

<br/>

<br/><b>Droit de s'opposer au traitement ou de le limiter</b><br/><br/>

<span >En cas de désaccord avec la façon dont nous traitons vos données personnelles, vous pouvez demander de cesser ou de limiter le traitement de ces dernières.</span>

<br/>

<br/><b>Droit de révoquer le consentement</b><br/><br/>

<span >Vous avez le droit de révoquer votre consentement au traitement de vos données à tout moment.</span>

<br/>

<span >La Municipalité de Folégandros fera tous les efforts possibles pour répondre à toutes vos demandes sans retard, et en tout cas dans le délai d'un mois à compter de sa réception. Ce délai peut être prolongé de deux (2) mois supplémentaires, dans la mesure du nécessaire en raison de la complexité de la demande et du nombre général de demandes reçues. La Municipalité de Folégandros vous informera d'une telle prolongation dans le délai d'un mois à compter de la réception de votre demande, en précisant les raisons de ce retard. Si vous avez envoyé votre demande par la voie électronique, l'information sera fournie par la voie électronique aussi, si possible, sauf si vous nous demandez de vous l'envoyer autrement.</span>

<br/>

<span >Quand la Municipalité de Folégandros satisfait votre demande qui consiste a) à limiter le traitement de vos données, ou b) à refuser le traitement de vos données, ou c) à effacer vos données des archives de l'Organisme, tandis que ces données sont indispensables pour conclure, maintenir ou exécuter un contrat, cette situation implique d'office soit la résiliation de ce contrat par vous, soit l'impossibilité de traiter votre demande.</span>

<br/>

<br/><b>Révocation du consentement</b><br/><br/>

<span >Vous avez le droit de révoquer votre consentement à tout moment. La révocation de votre consentement ne met pas en cause la légitimité du traitement de données, déjà effectué à titre de votre consentement avant la révocation de ce dernier.</span>

<br/>

<br/><b>Cookies</b><br/><br/>

<span >Pour garantir le bon fonctionnement de notre site et de notre application mobile, nous pouvons parfois placer un petit enregistrement des données, appelé "cookie", sur votre ordinateur ou sur votre appareil mobile pendant la visite de notre site. Un cookie est un fichier texte stocké par un serveur Web sur un ordinateur ou sur un appareil mobile. Le contenu d'un cookie ne peut être récupéré ou lu que par le serveur qui avait créé ce cookie. Le texte d'un fichier cookie se compose souvent d'identifiants, de noms de lieux et de certains chiffres et d'autres caractères. Les cookies sont uniques pour chaque logiciel de navigation et pour chaque application mobile utilisée par vous, et permet aux sites de remémorer vos données, par exemple, vos préférences.</span>

<br/>

<br/><b>Délégué à la protection des Données</b><br/><br/>

<span >Au cas où vous avez des questions au sujet de la protection de vos données et de vos droits, vous pouvez nous envoyer un courrier électronique à l'adresse </span><a href="mailto:dfolegandrou@5109.syzefxis.gov.gr"><span >dfolegandrou@5109.syzefxis.gov.gr</span></a><span >, ou une lettre par la poste à l'adresse: Chora Folégandros Cyclades code postal 84011, et nous y répondrons le plus vite possible, dans un mois au plus tard.</span>

<br/>

<br/><b>Autorité chargée de la protection des Données à caractère personnel</b><br/><br/>

<span >Vous avez le droit de porter plainte auprès de l'Autorité chargée de la protection des Données à caractère personnel (www.dpa.gr), qui est l'autorité compétente du contrôle de la protection des droits et libertés fondamentaux des personnes physiques en matière du traitement de leurs données personnelles, dans la mesure où vous considérez qu'une atteinte ait été portée à vos droits de façon quelconque.</span>

<br/>

<span >Nous vous invitons de consulter la Politique de protection de la vie privée et des données personnelles de l'Organisme en suivant le lien "Politique de protection de la vie privée et des données personnelles".</span>
    </div>
        </Fragment>
    )}

  const languageCtx = useContext(LanguageContext);

    const { t } = useTranslation();


  useEffect(() => {
   
  },[languageCtx.languageCode])
 

  return (
    <div>
   {(() => {
        switch(languageCtx.languageCode) {
          case 'el':
            return <PREL />
          case 'en':
            return <PREN />
          case 'fr':
            return <PRFR />
          default:
            return null
        }
      })()}
  
  </div>
  )
}