// ./app.js
import React, { Fragment,useRef, useEffect,useState,useContext } from "react"
import mapboxgl, {Marker, Popup} from "mapbox-gl"
import i18next from "i18next";
import cookies from "js-cookie";
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";

// import the mapbox styles
// alternatively can use a link tag in the head of public/index.html
// see https://docs.mapbox.com/mapbox-gl-js/api/
import "mapbox-gl/dist/mapbox-gl.css"
import User from "../User/User"
import geojson1 from "../../data/folegandros_1_1a_1b.json"
import geojson2 from "../../data/folegandros_2_2a.json"
import geojson3 from "../../data/folegandros_3_3a.json"
import geojson4 from "../../data/folegandros_4.json"
import geojson5 from "../../data/folegandros_5.json"
import geojson6 from "../../data/folegandros_6.json"

// Grab the access token from your Mapbox account
// I typically like to store sensitive things like this
// in a .env file
mapboxgl.accessToken = 'pk.eyJ1IjoibW50ZXRzaWsiLCJhIjoiY2tnOWFxNmoxMGlwdjJxbXlzM2d4a2l1YSJ9.1fap_mCwEdcXc8PehPrI9w'

function MapRoute({id}){

  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);
   const { t } = useTranslation();
  const [users, setUsers] = useState([])
  useEffect(()=>{
   fetch("https://folegandros.mitos.cityguideplatform.com/api/pois?lang=el", {
     headers: {
       'X-API-KEY': '8J5Yymc2pXzPUZnrqqYR'
     }
   })
     .then(response => response.json())
     .then(data => setUsers(data.success))
     .catch(err => console.log(err))
   },[languageCtx.languageCode]);

  const mapContainer = useRef()

  // this is where all of our map logic is going to live
  // adding the empty dependency array ensures that the map
  // is only rendered once
  useEffect(() => {
    // create the map and configure it
    // check out the API reference for more options
    // https://docs.mapbox.com/mapbox-gl-js/api/map/

   let geojson=geojson1;

switch (id) {
  case '1_4':
     geojson=geojson1;
    break
  case '1_5':
     geojson=geojson2;
    break
  case '1_6':
      geojson=geojson3;
     break
  case '1_7':
      geojson=geojson4;
    break
  case '1_8':
      geojson=geojson5;
     break
  case '1_9':
      geojson=geojson6;
     break
  default:
    console.log('Invalid Input')
}

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      center: [24.889502231934433,36.63977691482513],
     // interactive:'false',
      doubleClickZoom: false,
      touchZoomRotate: false,
      zoom: 12,
      style: {
        'version': 8,
        'sources': {
        'raster-tiles': {
        'type': 'raster',
        'tiles': [
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
        ],
        'tileSize': 256,
             }
        },
        'layers': [
        {
        'id': 'simple-tiles',
        'type': 'raster',
        'source': 'raster-tiles',
        'minzoom': 0,
        'maxzoom': 22
        }
        ]
        }
    })
    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
        enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
        }));

        map.on('load', function () {
          map.addSource("markers", {
              "type": "geojson",
              "data": geojson
          });
    
          map.addLayer({
            'id': 'LineString',
            'type': 'line',
            'source': 'markers',
            'layout': {
            'line-join': 'round',
            'line-cap': 'round',
            },
            'paint': {
            'line-color': '#ff6b01',
            'line-width': 5
            },
            'filter': ['!=', '$type', 'Point']
          })
   });

   
   {geojson.features.map((item,index) => { 
    const tp = `${item.geometry.type}`; 
     
    if (tp ==="Point" && languageCtx.languageCode === "el"){
      const coords = `${item.geometry.coordinates.slice()}`; 
    
      //const longt = coords;
      
      const sp = coords.split(',');
    
      const longt = sp[0];
      const lat = sp[1];
      const pin = [longt,lat];
      
      const pop = '<h4>'+`${item.properties.name}`+'</h4>'

      const popup = new mapboxgl.Popup({ offset: 15 })
            .setHTML(pop);

      new mapboxgl.Marker({color: '#ff6b01',rotation: 0})
      .setLngLat(pin)
      .setPopup(popup)
      .addTo(map) ; 
    }
    if (tp ==="Point" && languageCtx.languageCode === "en"){
      const coords = `${item.geometry.coordinates.slice()}`; 
    
      //const longt = coords;
      
      const sp = coords.split(',');
    
      const longt = sp[0];
      const lat = sp[1];
      const pin = [longt,lat];
      
      const pop = '<h4>'+`${item.properties.name_en}`+'</h4>'

      const popup = new mapboxgl.Popup({ offset: 15 })
            .setHTML(pop);

      new mapboxgl.Marker({color: '#ff6b01',rotation: 0})
      .setLngLat(pin)
      .setPopup(popup)
      .addTo(map) ; 
    }
    if (tp ==="Point" && languageCtx.languageCode === "fr"){
      const coords = `${item.geometry.coordinates.slice()}`; 
    
      //const longt = coords;
      
      const sp = coords.split(',');
    
      const longt = sp[0];
      const lat = sp[1];
      const pin = [longt,lat];
      
      const pop = '<h4>'+`${item.properties.name_en}`+'</h4>'

      const popup = new mapboxgl.Popup({ offset: 15 })
            .setHTML(pop);

      new mapboxgl.Marker({color: '#ff6b01',rotation: 0})
      .setLngLat(pin)
      .setPopup(popup)
      .addTo(map) ; 
    }
  })} 

   
  // Change the cursor to a pointer when the mouse is over the places layer.
  map.on('mouseenter', 'markers', () => {
  map.getCanvas().style.cursor = 'pointer';
  });
   
  // Change it back to a pointer when it leaves.
  map.on('mouseleave', 'markers', () => {
  map.getCanvas().style.cursor = '';
  });
 
    return () => map.remove()
  }, [languageCtx.languageCode])

  return (
    <Fragment>
      <div ref={mapContainer} style={{ width: "100%", height: "100vh" }} /> 
    </Fragment> 
  )
}

 export default MapRoute;