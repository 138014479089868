import React, { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import SingleTrip from "./SingleTripComponent"
import Moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';  
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css'; 

import el from 'date-fns/locale/el';
import fr from 'date-fns/locale/fr';
registerLocale('el', el)
registerLocale('fr', fr)
const EditTrip = ({uid, tid}) => {
  
  return (
    <>
     <div className="abtn11"><span className="addbtn11"><a href= {"/trip_id/" + tid}><img src="/assets/images/edit.png"/></a></span></div>
    </>
  );
}

export default EditTrip