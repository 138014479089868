import React, { Fragment,useEffect, useState,useContext } from "react";
import MapAllRoutes from "../MapRoute/MapAllRoutes"
import MapAllTrips from "../MapRoute/MapAllTrips"
import AddTripForm from "./AddTripFormComponent"
import EditTrip from "./EditTripComponent"
import DeleteTrip from "./DeleteTripComponent"
import AddTripFormNew from "./AddTripFormComponentNew"
import Affix from "react-simple-affix";
import i18next from "i18next";
import cookies from "js-cookie";
import { format } from 'date-fns';
import { enGB, el, fr } from 'date-fns/locale'
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";
import AuthContext from "../../store/auth-context";
import { Button,Modal} from 'react-bootstrap';  

function FetchUserTrips({uid}) {
  const [pois, initPois] = useState([])

  const [routes,setRoutes] = useState([])
  const [TPois,setTPois] = useState([])
  const authCtx = useContext(AuthContext);
  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  const fetchTrips = async () => {
    const furl = `https://folegandros.mitos.cityguideplatform.com/api/allItineraries?user_id=${uid}&lang=${languageCtx.languageCode}`;
    const response2 = await fetch(furl, {
      signal,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
      },
    })
     .catch((e) => {
       console.log(e.message)
     })
     if (!response2.ok) {
       throw new Error('Data could not be fetched!')
     } else {
        return response2.json()
     }
   }
   fetchTrips()
     .then((res2) => {
       setRoutes(res2.success)
     },[])
    
     return(
       <div> 
         {routes.length &&
           <Fragment>
             <div>
             <ul className="routelist">
               {routes.map((route,index) =>
                 <li className="routeitem" key={index} >
                    <div className="tripup">
                      <div className="routetitle">{route.name}</div> 
                      <div className="tripbtns">
                        <EditTrip uid={uid} tid={route.id}/> <DeleteTrip uid={uid} tid={route.id} name={route.name} start_date={route.start_date} end_date={route.end_date}/>
                      </div>
                    </div>
                   
                   {(() => {
                       switch (languageCtx.languageCode) {
                        case 'el':
                         return (
                           <div className="routedate"> {format(new Date(route.start_date), "eee dd LLL yyyy", { locale: el })} - {format(new Date(route.end_date), "eee dd LLL yyyy", { locale: el })}</div>
                           )
                        case 'fr':
                         return (
                           <div className="routedate"> {format(new Date(route.start_date), "eee dd LLL yyyy", { locale: fr })} - {format(new Date(route.end_date), "eee dd LLL yyyy", { locale: fr })}</div>
                         )
                       default:
                         return (
                           <div className="routedate"> {format(new Date(route.start_date), "eee dd LLL yyyy", { locale: enGB })} - {format(new Date(route.end_date), "eee dd LLLL yyyy", { locale: enGB })}</div>
                           )
                   }})()}
                 </li>
               )}
             </ul>
             {routes.length ? (
            <AddTripForm uid={authCtx.id}/> ):(<div></div>)}
            </div> 
            </Fragment>
           }

           {routes.length <= 0 &&
           <div>
             <div className="tripmsg">
               <br/>
               <img src="assets/images/bonbon-line.png" alt="folegandros line" /><br/>
              <p className="p"> {t('Δεν υπάρχει αποθηκευμένο ταξίδι. Πατήστε Δημιουργία για να σχεδιάσετε ένα ταξίδι.')} </p>
              </div>
              <AddTripFormNew uid={authCtx.id}/>
             </div>
           }
         </div>
       )
 }

function Trip() {
 
  const authCtx = useContext(AuthContext);
  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();
  const [users, setUsers] = useState([])
  const [uid,setUid] = useState([])
  return (
    <div>
    <div className="container11 section">
     <div className="row align-items-start equal">
      <div id="side-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
       <div className="sidemap">
        <MapAllTrips id='1_4'/>
        </div>
       </div>
         <div id="main-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
        
         
         {authCtx.isLoggedIn ? (
           //Fetch the trips for the current user
           <div>
                <h2>{t("Τα ταξίδια μου")}  </h2>
             <FetchUserTrips uid={authCtx.id}/>
                      
           </div>
         ) : (
           <div className="log">{t("Συνδεθείτε στην πλατφόρμα για να αποθηκεύσετε τις διαδρομές σας")}<br/>
             <div className="log">
               <span className="loginb1"><a href="/account/login"><button className="btn1 btn btn-secondary"> {t("Είσοδος")}</button></a></span>
               <span className="loginb"><a href="/account/sign-up"><button className="btn btn-secondary">{t("Εγγραφή")}</button></a></span>
             </div>
           </div>
           )}
        </div>
       </div>
     </div>
   </div>
  );
}

export default Trip;