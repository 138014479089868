import classes from "./User.module.css";
import accountClasses from "./Account.module.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "../../store/language-context";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";

const User = () => {
  const usernameRef = useRef("");
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const passwordRepeatRef = useRef("");
  const resetRef = useRef(null);
  const languageCtx = useContext(LanguageContext);
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState(null);

  const controller = new AbortController();
  const { signal } = controller;

  const { action } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    console.log(action);
    if (
      action !== "login" &&
      action !== "sign-up" &&
      action !== "password-recovery" &&
      action !== undefined
    ) {
      navigate("/", { replace: true });
      return;
    } else if (
      authCtx.isLoggedIn &&
      (action === "login" ||
        action === "sign-up" ||
        action === "password-recovery")
    )
      navigate("/account", { replace: true });
  }, [action, authCtx.isLoggedIn]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (action === "login") await login();
    else if (action === "sign-up") await signIn();
    else if (action === "password-recovery") await passwordRecovery();
  };

  useEffect(() => {
    resetRef.current.click();
    setError(null);
    console.log("clear");
  }, [action]);

  const login = async () => {
    if (!emailRef.current.value || !passwordRef.current.value) {
      setError(t("Παρακαλώ, συμπληρώστε όλα τα πεδία"));
      return;
    }
    const data = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    console.log(data);
    const loginRequest = await fetch(
      `https://folegandros.mitos.cityguideplatform.com/api/user/login`,
      {
        signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
        },
        body: JSON.stringify(data),
      }
    );

    const response = await loginRequest.json();

    console.log(response);

    if (response.error) setError(response.error.email);
    else setError(null);

    if (!response.data || !response.data.token) return;
    console.log(new Date().getTime());

    const expirationTime = new Date(new Date().getTime() + 60 * 60 * 1000);

    console.log(response, emailRef.current.value, passwordRef.current.value);
    navigate(-1, { replace: true });
    authCtx.login(
      response.data.token,
      expirationTime.toISOString(),
      response.data.user.id
    );
  };

  console.log(authCtx.id);
  const signIn = async () => {
    if (passwordRef.current.value !== passwordRepeatRef.current.value) {
      setError(t("Διαφορετικός κωδικός"));
      return;
    }

    const data = {
      name: usernameRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    const registerRequest = await fetch(
      `https://folegandros.mitos.cityguideplatform.com/api/${languageCtx.languageCode}/register`,
      {
        signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
        },
        body: JSON.stringify(data),
      }
    );

    const response = await registerRequest.json();
    if (response.error) setError(response.error);
    else {
      setError(null);
      navigate("/account/login", { replace: true });
    }

    console.log(response);
  };

  const passwordRecovery = async () => {
    if (!emailRef.current.value) {
      setError(
        t("Η συμπλήρωση του email είναι υποχρεωτική για αυτή την ενέργεια")
      );
      return;
    }

    const data = {
      email: emailRef.current.value,
    };

    const passwordRecoveryRequest = await fetch(
      `https://folegandros.mitos.cityguideplatform.com/api/${languageCtx.languageCode}/passwordForgot`,
      {
        signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
        },
        body: JSON.stringify(data),
      }
    );

    const response = await passwordRecoveryRequest.json();
    if (response.error) setError(response.error.email);
    else {
      setError(null);
      navigate("/account", { replace: true });
    }

    console.log(response);
  };

  return (
    <div className={classes.user_overlay}>
      <form onSubmit={onSubmitHandler}>
        {error && <div className={classes.user_error__message}>{error}</div>}
        {action === "sign-up" && (
          <>
            <label htmlFor={"username_field"}>{t("Όνομα")}</label>
            <input
              ref={usernameRef}
              type={"name"}
              name={"username"}
              id={"username_field"}
            />
          </>
        )}
        <label htmlFor={"email_field"}>E-mail</label>
        <input
          ref={emailRef}
          type={"email"}
          name={"userEmail"}
          id={"email_field"}
          required
        />
        {action !== "password-recovery" && (
          <Fragment>
            <label htmlFor={"password_field"}>{t("Κωδικός")}</label>
            <input
              ref={passwordRef}
              type={"password"}
              name={"userPassword"}
              id={"password_field"}
              required
            />
          </Fragment>
        )}
        {action === "sign-up" && (
          <>
            <label htmlFor={"re-password_field"}>
              {t("Επανάληψη κωδικού")}
            </label>
            <input
              ref={passwordRepeatRef}
              type={"password"}
              name={"userRePassword"}
              id={"re-password_field"}
              required
            />
          </>
        )}

        <div className={accountClasses.accountButtonContainer}>
          <button type={"submit"}>
            {action === "login"
              ? t("Είσοδος")
              : action === "sign-up"
              ? t("Δημιουργία")
              : t("Επόμενο")}
          </button>
          <button
            type={"button"}
            onClick={() => {
              action === "login" && navigate("/account/password-recovery");
              action === "sign-up" && navigate("/account/login");
              action === "password-recovery" && navigate("/account/login");
            }}
          >
            {action === "login" ? t("Ανάκτηση κωδικού") : t("Είσοδος")}
          </button>
          <button type={"reset"} ref={resetRef} style={{ display: "none" }}>
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};
export default User;
