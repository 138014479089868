import classes from "./Account.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useContext } from "react";
import AuthContext from "../../store/auth-context";
import User from "./User";
import { useTranslation } from "react-i18next";

let labelName;
const Account = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { action } = useParams();
  const { t } = useTranslation();

  if (action === "login") labelName = t("Είσοδος στον λογαριασμό σας");
  else if (action === "sign-up") labelName = t("Δημιουργία λογαριασμού");
  else if (action === "password-recovery")
    labelName = t("Δημιουργία νέου κωδικού πρόσβασης");
  else labelName = t("Καλώς ορίσατε,");

  return (
    <div className={classes.account_overlay}>
      <h2>{t(labelName)}</h2>
      {action === undefined && (
        <Fragment>
          <h1>{t("Εξερευνήστε την Φολέγανδρο")}</h1>
          <p>
            {t("Ψηφιακή Περιήγηση στις Διαδρομές Περιβαλλοντικού και Αγροτικού Ενδιαφέροντος της Φολεγάνδρου")}
          </p>

          <div className={classes.accountButtonContainer}>
          {authCtx.isLoggedIn}
            <button
              type={"button"}
              onClick={() => {
                if (!authCtx.isLoggedIn) navigate("login");
                else authCtx.logout();
              }}
            >
              {!authCtx.isLoggedIn ? t("Είσοδος") : t("Αποσύνδεση")} 
            </button>
            <button
              type={"button"}
              onClick={() => {
                if (!authCtx.isLoggedIn) navigate("sign-up");
                else navigate("/");
              }}
            >
              {!authCtx.isLoggedIn ? t("Δημιουργία λογαριασμού") : t("Αρχική")}
            </button>
          </div>
        </Fragment>
      )}

      {action && (
        <Fragment>
          <User />
        </Fragment>
      )}
    </div>
  );
};
export default Account;
