import React,{ Fragment,useContext } from 'react';
import { Link } from 'react-router-dom';
import LanguageContext from "./../store/language-context";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function Footer(props) {
    const languageCtx = useContext(LanguageContext);
    const {t} = useTranslation();
    return(
      <Fragment>
       <div className="footer">
         <div className="container">
         <div className="row justify-content-center">  
         <div className="col-4 col-xs-12 col-sm-6 col-m-4 col-lg-4"> 
             <a href="https://www.folegandros.gr" target="_blank"><img src="/assets/images/dimos_folegandros.png" alt="folegandros logo" className='flogo' /></a>
        </div>
        <div className="col-3 col-xs-12 col-sm-3 col-m-3 col-lg-3">
            <b>{t("Ιστότοπος")}</b>
            <ul className="list-unstyled">
                <li><Link to='/home'>{t("Αρχική")}</Link></li>
                <li><Link to='/routes'>{t("Διαδρομές")}</Link></li>
                <li><Link to='/information'>{t("Πληροφορίες")}</Link></li>
            </ul>
        </div>
        <div className="col-3 col-xs-12  col-sm-3 col-m-3 col-lg-3 apps2">
            <b>{t("Βοήθεια")}</b>
            <ul className="list-unstyled">
                <li><Link to='/privacypolicy'>{t("Πολιτική Απορρήτου")}</Link></li>
                <li><Link to='/terms'>{t("Όροι και Προϋποθέσεις")}</Link></li>
            </ul>
            <a href="https://apps.apple.com/app/id6443589662" target="_blank"><img src="/assets/images/app1.jpg" alt='app store' /></a>
            <a href="https://play.google.com/store/apps/details?id=gr.dotsoft.cityguide.folegandros" target="_blank"><img src="/assets/images/andr1.png" alt='google play' /></a>
        </div>
     
        </div>
        </div>
    </div>
    <div className="container">
    <div className="col-12 col-sm-12 col-m-12 col-lg-12"> 
      <div className='leaderlogo'>
        <img src="/assets/images/leader.png" alt="folegandros logo"  />
      </div>
      </div>
    </div>
    </Fragment>
    )
}

export default Footer;