import React, { Fragment,useState, useEffect, useContext } from 'react'
import {useLocation} from 'react-router-dom';

import i18next from "i18next";
import cookies from "js-cookie";
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";



export default function Info() {

  function INEL(){
    return(
    <Fragment>
  <div className="container section sectionp sectionp1">
  <div class="sep"><h3>Χρήσιμες Πληροφορίες</h3></div>     
<div class="wpb_wrapper">
<h4>Χρήσιμα τηλέφωνα</h4>

</div>

<div class="wpb_wrapper">
<ul className="ulp">
 	<li><strong>Δημαρχείο</strong> – Τηλ. : <a href="tel:22860 41416">22860 41416</a> – Email: <a href="mailto: dfolegandrou@5109.syzefxis.gov.gr"> dfolegandrou@5109.syzefxis.gov.gr</a></li>
 	<li><strong>Λιμεναρχείο</strong> – Τηλ. : <a href="tel:22860 41530">22860 41530</a></li>
 	<li><strong>Αστυνομικό Τμήμα</strong> – Τηλ. : <a href="tel:22860 41249">22860 41249</a></li>
 	<li><strong>Ιατρείο</strong> – Τηλ. : <a href="tel:22860 41222">22860 41222</a></li>
 	<li><strong>Φαρμακείο</strong> – Τηλ. : <a href="tel:+2286027640">22860 27640</a></li>
 	<li><strong>Κέντρο Εξυπηρέτησης Πολιτών</strong> – Τηλ. : <a href="tel:22860 27171">22860 27171</a> – Email: <a href="mailto:k.folegandrou@kep.gov.gr">k.folegandrou@kep.gov.gr</a></li>
 	<li><strong>Ταχυδρομείο</strong> – Τηλ. : <a href="tel:2022860 41299">22860 41299</a></li>
 	<li><strong>Πρατήριο Καυσίμων ELIN</strong> – Τηλ. : <a href="tel:2022860 41432">22860 41432</a></li>
 	<li><strong>Πρατήριο Καυσίμων SHELL</strong> – Τηλ. :  <a href="tel:2022860 41475">22860 41475</a></li>
</ul>
</div>
<div class="wpb_wrapper">
<div class="sep"><h3>Χρήσιμα Links</h3></div> 
</div>

<div className="container11 section">
     <div className="row align-items-start equal">
<div id="side-content" className="col-12 col-sm-6 col-m-4 col-lg-4">
<h4>Καιρός</h4>
<ul className="ulp">
 	<li><a href="http://www.accuweather.com/el/gr/folegandros/341/daily-weather-forecast/341?lang=el" target="_blank">AccuWeather</a></li>
 	<li><a href="http://freemeteo.gr/kairos/nisos-folegandros/7-imeres/pinakas/?gid=262318&amp;language=greek&amp;country=greece" target="_blank">FreeMeteo</a></li>
 	<li><a href="https://weather.yahoo.com/greece/aegean/folegandros-949648/" target="_blank">YahooWeather</a></li>
 	<li><a href="https://weather.com/el-GR/weather/today/l/GRXX0051:1:GR" target="_blank">TheWeatherChannel</a></li>
 	<li><a href="http://www.weather.gr/%CE%B5%CE%BB%CE%BB%CE%AC%CE%B4%CE%B1/%CE%BA%CF%85%CE%BA%CE%BB%CE%AC%CE%B4%CE%B5%CF%82/%CE%BA%CE%B1%CE%B9%CF%81%CF%8C%CF%82-%CE%A6%CE%BF%CE%BB%CE%AD%CE%B3%CE%B1%CE%BD%CE%B4%CF%81%CE%BF%CF%82-3213" target="_blank">Weather.gr</a></li>
 	<li><a href="http://www.windguru.cz/int/index.php?sc=468022&amp;sty=m_spot" target="_blank">WindGuru</a></li>
</ul>
</div>
<div id="side-content" className="col-12 col-sm-6 col-m-4 col-lg-4">
<div class="wpb_wrapper">
<h4>Δρομολόγια Πλοίων</h4>
<ul className="ulp">
 	<li><a href="http://www.aegeanspeedlines.gr/site/index.php/en/schedules/piraeus-serifos-sifnos-milos/itineraries-of-2016-piraeus-serifos-sifnos-milos" target="_blank" rel="noopener noreferrer">AEGEAN SPEED LINES</a></li>
 	<li><a href="https://hellenicseaways.gr" target="_blank" rel="noopener noreferrer">HELLENIC SEAWAYS</a></li>
 	<li><a href="https://www.seajets.gr" target="_blank" rel="noopener noreferrer">SEAJETS</a></li>
 	<li><a href="http://zanteferries.gr/portfolio-item/folegandros/?lang=en" target="_blank" rel="noopener noreferrer">ZANTE FERRIES</a></li>
</ul>
</div>
</div>
</div>
</div>
</div>
      </Fragment>
  )}

  function INEN(){
    return(
		<Fragment>
		<div className="container section sectionp sectionp1">
		<div class="sep"><h3>Useful Information</h3></div>     
	  <div class="wpb_wrapper">
	  <h4>Useful phone numbers</h4>
	  
	  </div>
	  
	  <div class="wpb_wrapper">
	  <ul class="ulp"><li><strong>CityHall</strong>&nbsp;– Tel. :&nbsp;<a href="tel:+30 22860 41416">+30 22860 41416</a>– Email: <a href="mailto: dfolegandrou@5109.syzefxis.gov.gr"> dfolegandrou@5109.syzefxis.gov.gr</a></li>
	  <li><strong>Port Authority</strong>&nbsp;– Tel. :&nbsp;<a href="tel:+30 22860 41530">+30 22860 41530</a></li><li><strong>Police Station</strong>&nbsp;– Τηλ. :&nbsp;<a href="tel:+30 22860 41249">+30 22860 41249</a></li><li><strong>Dispensary</strong>&nbsp;– Tel. :&nbsp;<a href="tel:+30 22860 41222">+30 22860 41222</a></li><li><strong>Pharmacy</strong>&nbsp;– Tel. :&nbsp;<a href="tel:+302286027640">+30 22860 27640</a></li><li><strong>Citizen Service Center </strong>&nbsp;– Tel. :&nbsp;<a href="tel:+30 22860 27171">+30 22860 27171</a>&nbsp;– Email:&nbsp;<a href="mailto:k.folegandrou@kep.gov.gr">k.folegandrou@kep.gov.gr</a></li><li><strong>Post Office </strong>&nbsp;– Tel. :&nbsp;<a href="tel:+30 2022860 41299">+30 22860 41299</a></li><li><strong>ELIN Petrol Station </strong>&nbsp;– Tel. :&nbsp;<a href="tel:+30 2022860 41432">+30 22860 41432</a></li><li><strong>SHELL Petrol Station </strong>&nbsp;– Tel. : &nbsp;<a href="tel:+30 2022860 41475">+30 22860 41475</a></li></ul>
	  </div>
	  <div class="wpb_wrapper">
	  <div class="sep"><h3>Useful Links</h3></div> 
	  </div>
	  
	  <div className="container11 section">
		   <div className="row align-items-start equal">
	  <div id="side-content" className="col-12 col-sm-6 col-m-4 col-lg-4">
	  <h4>Weather</h4>
	  <ul className="ulp">
		   <li><a href="http://www.accuweather.com/el/gr/folegandros/341/daily-weather-forecast/341?lang=en" target="_blank">AccuWeather</a></li>
		   <li><a href="http://freemeteo.gr/kairos/nisos-folegandros/7-imeres/pinakas/?gid=262318&amp;language=greek&amp;country=greece" target="_blank">FreeMeteo</a></li>
		   <li><a href="https://weather.yahoo.com/greece/aegean/folegandros-949648/" target="_blank">YahooWeather</a></li>
		   <li><a href="https://weather.com/el-GR/weather/today/l/GRXX0051:1:GR" target="_blank">TheWeatherChannel</a></li>
		   <li><a href="http://www.weather.gr/%CE%B5%CE%BB%CE%BB%CE%AC%CE%B4%CE%B1/%CE%BA%CF%85%CE%BA%CE%BB%CE%AC%CE%B4%CE%B5%CF%82/%CE%BA%CE%B1%CE%B9%CF%81%CF%8C%CF%82-%CE%A6%CE%BF%CE%BB%CE%AD%CE%B3%CE%B1%CE%BD%CE%B4%CF%81%CE%BF%CF%82-3213" target="_blank">Weather.gr</a></li>
		   <li><a href="http://www.windguru.cz/int/index.php?sc=468022&amp;sty=m_spot" target="_blank">WindGuru</a></li>
	  </ul>
	  </div>
	  <div id="side-content" className="col-12 col-sm-6 col-m-4 col-lg-4">
	  <div class="wpb_wrapper">
	  <h4>Ferry and speed boat schedules</h4>
	  <ul className="ulp">
		   <li><a href="http://www.aegeanspeedlines.gr/site/index.php/en/schedules/piraeus-serifos-sifnos-milos/itineraries-of-2016-piraeus-serifos-sifnos-milos" target="_blank" rel="noopener noreferrer">AEGEAN SPEED LINES</a></li>
		   <li><a href="https://www.hellenicseaways.gr/en-gb" target="_blank" rel="noopener noreferrer">HELLENIC SEAWAYS</a></li>
		   <li><a href="https://www.seajets.gr/en/" target="_blank" rel="noopener noreferrer">SEAJETS</a></li>
		   <li><a href="http://zanteferries.gr/portfolio-item/folegandros/?lang=en" target="_blank" rel="noopener noreferrer">ZANTE FERRIES</a></li>
	  </ul>
	  </div>
	  </div>
	  </div>
	  </div>
	  </div>
			</Fragment>

    )
    }

  function INFR(){
    return(
		<Fragment>
		<div className="container section sectionp sectionp1">
		<div class="sep"><h3>Informations utiles</h3></div>     
	  <div class="wpb_wrapper">
	  <h4>Numéros de téléphone utiles</h4>
	  
	  </div>
	  
	  <div class="wpb_wrapper">
	  <ul class="ulp">
		<li><strong>Mairie</strong>&nbsp;– Tél. :&nbsp;<a href="tel:+30 22860 41416">+30 22860 41416</a>– Email: <a href="mailto: dfolegandrou@5109.syzefxis.gov.gr"> dfolegandrou@5109.syzefxis.gov.gr</a></li>
		<li><strong>Autorité portuaire</strong>&nbsp;– Tél. :&nbsp;<a href="tel:+30 22860 41530">+30 22860 41530</a></li>
		<li><strong>Poste de police</strong>&nbsp;– Tél. :&nbsp;<a href="tel:+30 22860 41249">+30 22860 41249</a></li>
		<li><strong>Dispensaire</strong>&nbsp;– Tél. :&nbsp;<a href="tel:+30 22860 41222">+30 22860 41222</a></li>
		<li><strong>Pharmacie</strong>&nbsp;– Tél. :&nbsp;<a href="tel:+302286027640">+30 22860 27640</a></li>
		<li><strong>Centre de service aux citoyens</strong>&nbsp;– Tél. :&nbsp;<a href="tel:+30 22860 27171">+30 22860 27171</a>&nbsp;– Email:&nbsp;<a href="mailto:k.folegandrou@kep.gov.gr">k.folegandrou@kep.gov.gr</a></li>
		<li><strong>Bureau de poste</strong>&nbsp;– Tél. :&nbsp;<a href="tel:+30 2022860 41299">+30 22860 41299</a></li>
		<li><strong>ELIN Station-essence </strong>&nbsp;– Tél. :&nbsp;<a href="tel:+30 2022860 41432">+30 22860 41432</a></li>
		<li><strong>SHELL Station-essence</strong>&nbsp;– Tél. : &nbsp;<a href="tel:+30 2022860 41475">+30 22860 41475</a></li></ul>
	  </div>
	  <div class="wpb_wrapper">
	  <div class="sep"><h3>Liens utiles</h3></div> 
	  </div>
	  
	  <div className="container11 section">
		   <div className="row align-items-start equal">
	  <div id="side-content" className="col-12 col-sm-6 col-m-4 col-lg-4">
	  <h4>Temps</h4>
	  <ul className="ulp">
		   <li><a href="http://www.accuweather.com/el/gr/folegandros/341/daily-weather-forecast/341?lang=en" target="_blank">AccuWeather</a></li>
		   <li><a href="http://freemeteo.gr/kairos/nisos-folegandros/7-imeres/pinakas/?gid=262318&amp;language=greek&amp;country=greece" target="_blank">FreeMeteo</a></li>
		   <li><a href="https://weather.yahoo.com/greece/aegean/folegandros-949648/" target="_blank">YahooWeather</a></li>
		   <li><a href="https://weather.com/el-GR/weather/today/l/GRXX0051:1:GR" target="_blank">TheWeatherChannel</a></li>
		   <li><a href="http://www.weather.gr/%CE%B5%CE%BB%CE%BB%CE%AC%CE%B4%CE%B1/%CE%BA%CF%85%CE%BA%CE%BB%CE%AC%CE%B4%CE%B5%CF%82/%CE%BA%CE%B1%CE%B9%CF%81%CF%8C%CF%82-%CE%A6%CE%BF%CE%BB%CE%AD%CE%B3%CE%B1%CE%BD%CE%B4%CF%81%CE%BF%CF%82-3213" target="_blank">Weather.gr</a></li>
		   <li><a href="http://www.windguru.cz/int/index.php?sc=468022&amp;sty=m_spot" target="_blank">WindGuru</a></li>
	  </ul>
	  </div>
	  <div id="side-content" className="col-12 col-sm-6 col-m-4 col-lg-4">
	  <div class="wpb_wrapper">
	  <h4>Horaires des ferries et hors-bord</h4>
	  <ul className="ulp">
		   <li><a href="http://www.aegeanspeedlines.gr/site/index.php/en/schedules/piraeus-serifos-sifnos-milos/itineraries-of-2016-piraeus-serifos-sifnos-milos" target="_blank" rel="noopener noreferrer">AEGEAN SPEED LINES</a></li>
		   <li><a href="https://www.hellenicseaways.gr/en-gb" target="_blank" rel="noopener noreferrer">HELLENIC SEAWAYS</a></li>
		   <li><a href="https://www.seajets.gr/en" target="_blank" rel="noopener noreferrer">SEAJETS</a></li>
		   <li><a href="http://zanteferries.gr/portfolio-item/folegandros/?lang=en" target="_blank" rel="noopener noreferrer">ZANTE FERRIES</a></li>
	  </ul>
	  </div>
	  </div>
	  </div>
	  </div>
	  </div>
			</Fragment>

    )}

  const languageCtx = useContext(LanguageContext);

    const { t } = useTranslation();


  useEffect(() => {
   
  },[languageCtx.languageCode])
 

  return (
    <div>
   {(() => {
        switch(languageCtx.languageCode) {
          case 'el':
            return <INEL />
          case 'en':
            return <INEN />
          case 'fr':
            return <INFR />
          default:
            return null
        }
      })()}
  
  </div>
  )
}