import React, { Fragment,useEffect, useState,useContext } from "react";
import MapAllRoutes from "../MapRoute/MapAllRoutes"
import MapAllTrips from "../MapRoute/MapAllTrips"
import Affix from "react-simple-affix";
import i18next from "i18next";
import cookies from "js-cookie";
import { format } from 'date-fns';
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";
import AuthContext from "../../store/auth-context";
import { Button,Modal} from 'react-bootstrap';  
import TripCalEn from "./TripCalComponentEn";
import TripCalFr from "./TripCalComponentFr";
import TripCal from "./TripCalComponent";
import Moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';  
import el from 'date-fns/locale/el';
import fr from 'date-fns/locale/fr';

registerLocale('el', el)
registerLocale('fr', fr)

 function FetchMyTrip({uid}) {
    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const { signal } = controller;
    const languageCtx = useContext(LanguageContext);
    const { t } = useTranslation();
    const [trip, initTrip] = useState([]);

    const tid = window.location.pathname.split("/").pop();


    const fetchData = async () => {
    
         const furl = `https://folegandros.mitos.cityguideplatform.com/api/allItineraries?user_id=${uid}&lang=${languageCtx.languageCode}`;
      
          const response = await fetch(furl, {signal,
            headers: {
              'X-API-KEY': '8J5Yymc2pXzPUZnrqqYR',
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          })
          if (!response.ok) {
            throw new Error('Data could not be fetched!')
          } else {
            return response.json()
          }
        }
        useEffect(() => {
          fetchData()
            .then((res) => {
              initTrip(res.success)
            })
            
            .catch((e) => {
              console.log(e.message)
            })
        },[languageCtx.languageCode])

    return(
    <div>
      <div className="container11 section">
        <div className="row align-items-start equal">
          <div id="side-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
            <div className="sidemap">
               <MapAllTrips id='1_4' />
            </div>
          </div>
         <div id="main-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
          {trip.length > 0 &&
           <div>
            {trip.map((trip) =>
             <div>
               {trip.id == tid &&
               <div>
                <h2>{trip.name}  </h2>
                <div className='addr'>{t("Πατήστε + για να προσθέσετε μία διαδρομή")}</div>
                {languageCtx.languageCode === "fr" ? (
                    <TripCalFr uid={uid} tid={trip.id} start={trip.start_date} duration={Moment(trip.end_date).diff(Moment(trip.start_date), 'days')}/>
                ) : ""}
                {languageCtx.languageCode === "en" ? (
                    <TripCalEn uid={uid} tid={trip.id} start={trip.start_date} duration={Moment(trip.end_date).diff(Moment(trip.start_date), 'days')}/>
                ) : ""}
                 {languageCtx.languageCode === "el" ? (
                    <TripCal uid={uid} tid={trip.id} start={trip.start_date} duration={Moment(trip.end_date).diff(Moment(trip.start_date), 'days')}/>
                ) : ""}
                </div>
               }
             </div>
            )}
           </div>
          }
         </div>
       </div>
      </div>
    </div>
    )
   }
function SingleTrip(uid) {
 
  const authCtx = useContext(AuthContext);
  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <div>
       {authCtx.isLoggedIn ? (
           //Fetch the trips for the current user
           <div>
             <FetchMyTrip uid={authCtx.id} />
           </div>
         ) : (
           <div className="log">{t("Συνδεθείτε στην πλατφόρμα για να αποθηκεύσετε τις διαδρομές σας")}<br/>
             <div className="log">
               <span className="loginb1"><a href="/account/login"><button className="btn1 btn btn-secondary"> {t("Είσοδος")}</button></a></span>
               <span className="loginb"><a href="/account/sign-up"><button className="btn btn-secondary">{t("Εγγραφή")}</button></a></span>
             </div>
           </div>
           )}
   </div>
  );
}

export default SingleTrip;