import React, { Fragment,useEffect, useState,useContext } from "react";
import Affix from "react-simple-affix";
import i18next from "i18next";
import cookies from "js-cookie";
import { format } from 'date-fns';
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";
import AuthContext from "../../store/auth-context";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import Moment from 'moment';
import 'moment/min/moment-with-locales'
import 'moment/locale/el';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';  
import el from 'date-fns/locale/el';
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr)
registerLocale('el', el)

function TripCAl({uid,tid,start, duration}) {
  
  const final = [];
  const [show, setShow] = useState(false);
  const [poiid, setName] = useState([]);
  const [day, setDay] = useState([]);
  const [trips, setTrips] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const authCtx = useContext(AuthContext);
  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  const fetchPoiTrips = async () => {
    const furl = `https://folegandros.mitos.cityguideplatform.com/api/itineraryPois?user_id=${uid}&id=${tid}&lang=${languageCtx.languageCode}`;
    
    console.log(furl)
    const response2 = await fetch(furl, {
      signal,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
      },
    })
     .catch((e) => {
       console.log(e.message)
     })
     if (!response2.ok) {
       throw new Error('Data could not be fetched 1234!')
     } else {
        return response2.json()
     }
   }
   fetchPoiTrips()
     .then((res2) => {
       setTrips(res2.success)
     },[])

  function handleShow1(tday){
    handleShow();
    setDay(tday);
  }
    
  for (let i = 0; i <= duration; i++) {
    final.push(
        <>
         {languageCtx.languageCode === "el" &&
            <li key={i}>{Moment(start).add(i,'days').format("DD ddd ",{ locale: el})}</li>
         }
        {languageCtx.languageCode === "en" &&
            <li key={i}>{Moment(start).add(i,'days').format("DD ddd ")}</li>
         }
         {languageCtx.languageCode === "fr" &&
            <li key={i}>{Moment(start).add(i,'days').format("DD ddd ",{ locale: fr})}</li>
         }
    </>
    );
  }
  function Add2Trip(){
  
    //const d1 = Moment(startDate).format('YYYY-MM-DD');
    //const d2 = Moment(endDate).format('YYYY-MM-DD');
    
   const furl = `https://folegandros.mitos.cityguideplatform.com/api/itineraryPoi?itinerary_id=${tid}&user_id=${uid}&day=${start}&period_id=1&poi_id=${poiid}&deleted=0&lang=${languageCtx.languageCode}`;

   const response1 =  fetch(furl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
        },
      })
      if (!response1.ok) {
        throw new Error('Data could not be fetched!')
      } else {
        return response1.json()
    }
 
  }

  return (
 <>
    <div className="tripcal"> 
      <Tabs defaultActiveKey="0">
          {final.map((day1,index ) => (
            <Tab eventKey={index} id={"calday_" + index} title={day1}>
              <Fragment>
             <div>
              {tid == '7AlThqKdFkQwtoZoUln4JTB1vEMTQNz4aG5a5qxb' &&
              index==1 &&
             <ul className="routelist">
              
             <li className="routeitem">
              <div className="routetag tag1_4"> Διαδρομή 1 και κλάδοι 1Α και 1Β </div>
              <div className="routetitle"> <a href="/route/1_4">Από την απόκρημνη Χώρα στη θαλασσινή Αγκάλη </a></div>
              <div>Διαδρομή 1: Χώρα – Σταυρός – Χριστός – παραλία Φηρά – παραλία Αγκάλης, <br/>Κλάδος  1Α: Σταυρός – Ανεμόμυλοι – παραλία Βορεινά, <br/>Κλάδος 1B: Αγία Παρασκευή – Γιώργη τ' Αγά</div>
              <div className="mcolumns measurements"><div className="mcolumn"><div>Απόσταση (χλμ)</div><span><img src="/assets/images/icons-distance.png" alt="folegandros logo"/>
                <div>Δ1 3.5<br/>Κ1Α 1.1<br/>Κ1Β 1.6</div></span></div><div class="mcolumn"><div>Χρόνος ('Ωρες)</div><span><img src="/assets/images/icons-alarm-2.png" alt="folegandros logo"/><div>1 1:30<br/><br/>1A 0:45<br/>1Β 0:30</div></span></div>
                <div className="mcolumn"><div>Βαθμίδα </div><span><img src="/assets/images/icons-like.png" alt="folegandros logo" /><div>Εύκολη</div></span></div>
                </div></li>
            
             </ul>
            }
             {tid == 'yJ61HTaFwvNs7i4ggdMwwJH5bOnOtM9SJulIG95u' &&
              index==0 &&
             <ul className="routelist">
              
             <li className="routeitem">
              <div className="routetag tag1_4"> Διαδρομή 1 και κλάδοι 1Α και 1Β </div>
              <div className="routetitle"> <a href="/route/1_4">Από την απόκρημνη Χώρα στη θαλασσινή Αγκάλη </a></div>
              <div>Διαδρομή 1: Χώρα – Σταυρός – Χριστός – παραλία Φηρά – παραλία Αγκάλης, <br/>Κλάδος  1Α: Σταυρός – Ανεμόμυλοι – παραλία Βορεινά, <br/>Κλάδος 1B: Αγία Παρασκευή – Γιώργη τ' Αγά</div>
              <div className="mcolumns measurements"><div className="mcolumn"><div>Απόσταση (χλμ)</div><span><img src="/assets/images/icons-distance.png" alt="folegandros logo"/>
                <div>Δ1 3.5<br/>Κ1Α 1.1<br/>Κ1Β 1.6</div></span></div><div class="mcolumn"><div>Χρόνος ('Ωρες)</div><span><img src="/assets/images/icons-alarm-2.png" alt="folegandros logo"/><div>1 1:30<br/><br/>1A 0:45<br/>1Β 0:30</div></span></div>
                <div className="mcolumn"><div>Βαθμίδα </div><span><img src="/assets/images/icons-like.png" alt="folegandros logo" /><div>Εύκολη</div></span></div>
                </div></li>
            
             </ul>
            }
             </div>
             </Fragment>
              <div className="abtn"><span className="addbtn" onClick={e => handleShow1(Moment({day1}).format('YYYY-MM-DD'))}>+</span></div>
            </Tab>
        ))}
       </Tabs>
    </div>

<Modal show={show} onHide={handleClose}>
<Modal.Header closeButton>
<Modal.Title></Modal.Title>
</Modal.Header>
<Modal.Body>
<Label htmlFor="confirm" className="leb">{t("Προσθήκη στο ταξίδι μου")}</Label>
<Form  className="add2trip" onSubmit={() => { Add2Trip()}}>                       
        {languageCtx.languageCode === "el" &&
              <Fragment>
                <FormGroup><ul>
                    <li><Label htmlFor="route" className="routepop"><span>Επιλέξτε διαδρομή</span></Label></li>
                    <li><input type="radio" value="1_4" name="route"  onClick={e => setName('1_4')}/> <span>Από την απόκρημνη Χώρα στη θαλασσινή Αγκάλη</span></li>
                    <li><input type="radio" value="1_5" name="route" onClick={e => setName('1_5')}/> <span>Ο δρόμος της αγροτικής ζωής</span></li>
                    <li><input type="radio" value="1_6" name="route" onClick={e => setName('1_6')}/><span> Αναζητώντας το νερό και τους ψαράδες</span></li>
                    <li><input type="radio" value="1_7" name="route" onClick={e => setName('1_7')}/><span> Οι λαχανόκηποι της Φολεγάνδρου</span></li>
                    <li><input type="radio" value="1_8" name="route" onClick={e => setName('1_8')}/><span> Με οδηγό την άγρια ομορφιά των μαρμάρων</span></li>
                    <li><input type="radio" value="1_9" name="route" onClick={e => setName('1_9')}/><span> Στις θεμωνιές του Πετούση και του Λιβαδιού</span></li>
                    </ul></FormGroup>
                    <Button type="submit" value="next" color="secondary" className="mbtn1">{t("Επόμενο")}</Button>
                </Fragment>
        }
        {languageCtx.languageCode === "en" &&
              <Fragment>
                <FormGroup><ul>
                    <li><Label htmlFor="route" className="routepop"><span>Choose a route</span></Label></li>
                    <li><input type="radio" value="1_4" name="route"  onClick={e => setName('1_4')}/> <span>From the steep Chora to the seaside Agali</span></li>
                    <li><input type="radio" value="1_5" name="route" onClick={e => setName('1_5')}/> <span>The road of rural life</span></li>
                    <li><input type="radio" value="1_6" name="route" onClick={e => setName('1_6')}/><span>Searching for the water and the fishermen</span></li>
                    <li><input type="radio" value="1_7" name="route" onClick={e => setName('1_7')}/><span> The vegetable gardens of Folegandros</span></li>
                    <li><input type="radio" value="1_8" name="route" onClick={e => setName('1_8')}/><span> With the wild beauty of the marbles as a guide</span></li>
                    <li><input type="radio" value="1_9" name="route" onClick={e => setName('1_9')}/><span> To the "themonies" of Petousis and Livadi</span></li>
                    </ul></FormGroup>
                    <Button type="submit" value="next" color="secondary" className="mbtn1">{t("Επόμενο")}</Button>
                    </Fragment>
        }
        {languageCtx.languageCode === "fr" &&
              <Fragment>
                <FormGroup><ul>
                    <li><Label htmlFor="route" className="routepop"><span>Choisissez un itinéraire</span></Label></li>
                    <li><input type="radio" value="1_4" name="route"  onClick={e => setName('1_4')}/> <span>De la Chora escarpée à l'Agali balnéaire</span></li>
                    <li><input type="radio" value="1_5" name="route" onClick={e => setName('1_5')}/> <span>La route de la vie rurale</span></li>
                    <li><input type="radio" value="1_6" name="route" onClick={e => setName('1_6')}/><span>Cherchant l'eau et les pêcheurs</span></li>
                    <li><input type="radio" value="1_7" name="route" onClick={e => setName('1_7')}/><span> Les potagers de Folegandros</span></li>
                    <li><input type="radio" value="1_8" name="route" onClick={e => setName('1_8')}/><span> Avec pour guide la beauté sauvage des marbres</span></li>
                    <li><input type="radio" value="1_9" name="route" onClick={e => setName('1_9')}/><span> Aux "thémonies" de Petousis et Livadi</span></li>
                    </ul></FormGroup>
                    <Button type="submit" value="next" color="secondary" className="mbtn1">{t("Επόμενο")}</Button>
                    </Fragment>
        }
                </Form>
</Modal.Body>
</Modal>
</>
  );
}

export default TripCAl;