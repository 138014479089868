import React, { Fragment, useState,useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";
import AuthContext from "../../store/auth-context";
import Moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';  
   
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css'; 

import el from 'date-fns/locale/el';
import fr from 'date-fns/locale/fr';
registerLocale('el', el)
registerLocale('fr', fr)
const AddTripFormNew = ({uid}) => {

  const authCtx = useContext(AuthContext);
  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [name, setName] = useState([])
  const [step, setStep] = useState(['1']);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  
  function AddTrip(){
  
    const d1 = Moment(startDate).format('YYYY-MM-DD');
    const d2 = Moment(endDate).format('YYYY-MM-DD');
    
    const furl = `https://folegandros.mitos.cityguideplatform.com/api/itinerary?user_id=${uid}&name=${name}&start_date=${d1}&end_date=${d2}&deleted=0`;
  
  const response1 =  fetch(furl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
        },
      })
      if (!response1.ok) {
        throw new Error('Data could not be fetched!')
      } else {
        return response1.json()
    }
 
  }
  return (
    <>
       <div className="abtn1"><button className="btn1" onClick={handleShow}>Δημιουργία</button></div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  onSubmit={() => { AddTrip()}}>                       
                            {step <=1 &&
                            <Fragment>
                              <FormGroup>
                                <Label htmlFor="name" className="leb">{t("Όνομα διαδρομής")}</Label>
                                <Input type="text" id="name" name="name"
                                    onChange={e => setName(e.target.value)}/>
                            </FormGroup>
                            <Button type="submit" className="mbtn" value="next" color="secondary" onClick={e => setStep(step+1)}>{t("Επόμενο")}</Button>
                            </Fragment>
                            }
                            {step >=2 &&
                            <Fragment>
                              <Label htmlFor="date" className="leb">{t("Ημερομηνία")}</Label>
                              <FormGroup>
                              {languageCtx.languageCode === "en" &&
                              <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                withPortal
                              />
                              }
                              {languageCtx.languageCode === "el" &&
                              <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                withPortal
                                locale="el"
                              />
                              }
                              {languageCtx.languageCode === "fr" &&
                              <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                withPortal
                                locale="fr"
                              />
                              }
                              </FormGroup>
                             <Button className="mbtn" type="submit" value="submit" color="primary" onClick={handleClose}>{t("Αποθήκευση")}</Button>
                            </Fragment>
                            }
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTripFormNew