import React, { Fragment, useState, useEffect, useContext,useRef } from 'react'
import {useLocation} from 'react-router-dom';
import MapRoute from "../MapRoute/MapRoute"
import Accordion from 'react-bootstrap/Accordion';
import StickyBox from "react-sticky-box";
import ReactAudioPlayer from "react-audio-player";
import { IMuiFbPhotoGridImage, MuiFbPhotoGrid } from 'mui-fb-photo-grid';
import Affix from "react-simple-affix";
import i18next from "i18next";
import cookies from "js-cookie";
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player/youtube';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DOMPurify from "dompurify";

export default function SingleRoute() {
  const [poi, initPoi] = useState([])
  const [poiK, initKnow] = useState([])
  const [subt, initSubt] = useState([])
  const [poiVal, initPoiVal] = useState([])
  const [poiDist, initPoiDist] = useState([])
  const [poiTime, initPoiTime] = useState([])
  const [poiDif, initPoiDif] = useState([])
  const [poiAudio, initPoiAudio] = useState([])
  const [poiGallery, initPoiGallery] = useState([])
  const [poiVideo, initPoiVideo] = useState([])
  const [openac, setopenac] = useState([-1])
  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);


  const { t } = useTranslation();

  const ref = useRef(null);

  const fetchData = async () => {
    
  const pid = window.location.pathname.split("/").pop();
  const furl = `https://folegandros.mitos.cityguideplatform.com/api/poi?fetch_files=1&id=${pid}&lang=${languageCtx.languageCode}`;
  
  const response = await fetch(furl, {signal,
      headers: {
        'X-API-KEY': '8J5Yymc2pXzPUZnrqqYR',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  useEffect(() => {
    fetchData()
      .then((res) => {
        initPoi(res.success)
        initSubt(res.success.extras._subtitle.value)
        initKnow(res.success.extras._did_you_know.value)
        initPoiVal(res.success.extras._content_points.value)
        initPoiDist(t(res.success.extras._distance.value))
        initPoiTime(t(res.success.extras._time.value))
        initPoiDif(t(res.success.extras._difficulty.value))
        initPoiAudio(res.success.files[4])
        initPoiGallery(res.success.files[1])
        initPoiVideo(res.success.files[5])
      })
      
      .catch((e) => {
        console.log(e.message)
      })
  },[languageCtx.languageCode])

  useEffect(() => {
    const handleClick = event => {
      if((event.target.tagName) == 'A'){
      // alert(event.target.href.split("#did_you_know_").pop())
        
       setopenac(event.target.href.split("#did_you_know_").pop())
      }
    };

    const element = ref.current;

    element.addEventListener('click', handleClick);

    return () => {
      element.removeEventListener('click', handleClick);
    };
  }, [openac]);

  const IMAGES = [];
  const pid = `${poi.id}`
  
  const settings = {
    dots: false,
    arrows:true,
    autoplay: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode:true,
    adaptiveHeight:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <Fragment>
  {console.log(poi)}
  
   <div className="container11 section">
   <div id="side-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
   
        { poi.id == '1_4' &&
        <Affix
          fixedNavbarSelector="#navbar"
          relativeElementSelector="#main-content"
        >
             
        <div className="sidenav">
        <div>
          <MapRoute id='1_4'/>
          <div>
            <br />
            <h3>{t("Υψομετρικό Προφίλ")}</h3><br />
            {t("Διαδρομή 1")}
            <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=112791108&measures=ον&title=off&near=off&images=off&maptype=H&controls=on&profile=on" width="100%" height="250"></iframe>
            {t("Κλάδος 1Α")}
            <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=112791604&measures=off&title=off&near=off&images=off&maptype=H&controls=on&profile=on" width="100%" height="250"></iframe>
            {t("Κλάδος 1B")}
            <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=112792156&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
           </div>
        </div>
         </div>
         </Affix>
        }
        { poi.id == '1_5' &&
         <Affix
         fixedNavbarSelector="#navbar"
         relativeElementSelector="#main-content"
       >
            
       <div className="sidenav1">
         <div>
         <MapRoute id='1_5'/>
         <div>
           <br />
           <h3>{t("Υψομετρικό Προφίλ")}</h3><br />
           {t("Διαδρομή 2")}
           <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=113720393&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
           {t("Κλάδος 2Α")}
           <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=113720829&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
          </div>
       </div>
       </div>
       </Affix>
        }
        { poi.id == '1_6' &&
        <Affix
        fixedNavbarSelector="#navbar"
        relativeElementSelector="#main-content"
      >
           
      <div className="sidenav1">
          <div>
          <MapRoute id='1_6'/>
          <div>
            <br />
            <h3>{t("Υψομετρικό Προφίλ")}</h3><br />
            {t("Διαδρομή 3")}
             <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=113722480&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
             {t("Κλάδος 3Α")}
             <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=113722549&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
              </div>
        </div>
        </div>
       </Affix>
        }
        { poi.id == '1_7' &&
               <Affix
               fixedNavbarSelector="#navbar"
               relativeElementSelector="#main-content"
             >
       <div className="sidenav2">
          <div>
          <MapRoute id='1_7'/>
        
          <div>
            <br />
            <h3>{t("Υψομετρικό Προφίλ")}</h3><br />
            {t("Διαδρομή 4")}
             <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=113722650&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
            </div>
        </div>
        </div>
        </Affix>
        }
         { poi.id == '1_8' &&
          <Affix
          fixedNavbarSelector="#navbar"
          relativeElementSelector="#main-content"
        >
             
        <div className="sidenav2">
          <div >
              <MapRoute id='1_8'/>
              <div>
                <br />
                <h3>{t("Υψομετρικό Προφίλ")}</h3><br />
                {t("Διαδρομή 5")}
                 <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=113722723&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
                </div>
            </div>
            </div>
        </Affix>
        }
        { poi.id == '1_9' &&
         <Affix
         fixedNavbarSelector="#navbar"
         relativeElementSelector="#main-content"
       >
            
       <div className="sidenav2">
          <div>
          <MapRoute id='1_9'/>
          <div>
            <br />
            <h3>{t("Υψομετρικό Προφίλ")}</h3><br />
             {t("Διαδρομή 6")}
             <iframe frameBorder="0" scrolling="no" src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=113722756&measures=off&title=off&near=off&images=off&maptype=H&profile=on" width="100%" height="250"></iframe>
            </div>
        </div>
        </div>
        </Affix>
        }
         
      </div>

      <div id="main-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
            
      <div className="routetag"> {poi.name} </div>
          <div className="routetitle"> {subt}</div>
          {languageCtx.languageCode === "el" ? (
                    <div dangerouslySetInnerHTML={ { __html: JSON.stringify(poiVal).replace("Κλάδος", "<br />Κλάδος ").replaceAll("Κλάδος1Β", "<br />Κλάδος 1B").replaceAll("\"","")} }></div>
                ) : ""}
                {languageCtx.languageCode === "en" ? (
                    <div dangerouslySetInnerHTML={ { __html: JSON.stringify(poiVal).replace("Branch", "<br />Branch ").replaceAll("Trail", "<br />Trail").replaceAll(",", "<br />").replaceAll("\"","")} }></div>
                ) : ""}
                {languageCtx.languageCode === "fr" ? (
                    <div dangerouslySetInnerHTML={ { __html: JSON.stringify(poiVal).replaceAll("Branche", "<br />Branche ").replaceAll("1A", "<br />1A").replaceAll(",", "<br />").replaceAll("\"","")} }></div>
                ) : ""}
                <div className="mcolumns measurements">
                { poiDist &&
                <div className="mcolumn">
                  <Fragment> 
                    <div>{t("Απόσταση")} ({t("χλμ")})</div>
                    <span><img src="/assets/images/icons-distance.png" alt="folegandros logo" /><div dangerouslySetInnerHTML={ { __html: JSON.stringify(poiDist).replaceAll(",","<br />").replaceAll("\"","")} }></div></span>
                  </Fragment>
                  </div>
                  }
                  { poiTime &&
                  <div className="mcolumn">
                  <Fragment> 
                    <div>{t("Χρόνος")} ({t("'Ωρες")})</div>
                    <span><img src="/assets/images/icons-alarm-2.png" alt="folegandros logo" /><div dangerouslySetInnerHTML={ { __html: JSON.stringify(poiTime).replaceAll(",","<br />").replaceAll("\"","")} }></div></span>
                  </Fragment>
                  </div>
                   }
                   { poiDif &&
                  <div className="mcolumn">
                  <Fragment> 
                    <div>{t("Βαθμίδα")} </div>
                    <span><img src="/assets/images/icons-like.png" alt="folegandros logo" /><div dangerouslySetInnerHTML={ { __html: JSON.stringify(poiDif).replaceAll(",","<br />").replaceAll("\"","")} }></div></span>
                  </Fragment>
                  </div>
                  }
                     <div className="mcolumn">
                  <Fragment> 
                    {poi.id==='1_4' &&
                      <>
                         <div><span className="kdown"><a href="/kmz/folegandros_1.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                         <div><span className="kdown"><a href="/kmz/folegandros_1a.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                         <div><span className="kdown"><a href="/kmz/folegandros_1b.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                      </>
                    }
                    {poi.id==='1_5' &&
                      <>
                         <div><span className="kdown"><a href="/kmz/folegandros_2.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                         <div><span className="kdown"><a href="/kmz/folegandros_2a.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                      </>
                    }
                    {poi.id==='1_6' &&
                        <>
                        <div><span className="kdown"><a href="/kmz/folegandros_3.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                        <div><span className="kdown"><a href="/kmz/folegandros_3a.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                     </>
                    }
                    {poi.id==='1_7' &&
                         <div><span className="kdown"><a href="/kmz/folegandros_4.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                    }
                    {poi.id==='1_8' &&
                         <div><span className="kdown"><a href="/kmz/folegandros_5.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                    }
                    {poi.id==='1_9' &&
                         <div><span className="kdown"><a href="/kmz/folegandros_6.kmz"><img src='/assets/images/kml.png' />{t("Αποθήκευση")}</a></span></div>
                    }
                  </Fragment>
                  </div> 
                </div>
                <div> 
                 
                  <br />
                <h3>{t("Αναλυτική Περιγραφή")}</h3>
                <span>
                   {languageCtx.languageCode === "el" ? (
                    poiAudio.map((a3,index2 ) => (
                      index2==0?
                      <div><h4>{a3.caption}</h4><br/><ReactAudioPlayer src={a3.url}  controls /></div> :""
                     ))
                    ) : ("")}
                    {languageCtx.languageCode === "en" ? (
                    poiAudio.map((a3,index2 ) => (
                      index2==1?
                      <div><h4>{a3.caption}</h4><br/><ReactAudioPlayer src={a3.url}  controls /></div> :""
                     ))
                    ) : ("")}
                    {languageCtx.languageCode === "fr" ? (
                    poiAudio.map((a3,index2 ) => (
                      index2==2?
                      <div><h4>{a3.caption}</h4><br/><ReactAudioPlayer src={a3.url}  controls /></div> :""
                     ))
                    ) : ("")}
               </span> 
                </div>
                <br />
                
                <div className="main_description" id="main_description"  ref={ref}>
                  <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(poi.portal_main_info)}}></div>
                </div>
        </div>
      </div>
<div>
     <div className="row align-items-start equal">       
        <div className="col-12 col-sm-12 col-m-6 col-lg-6">
           </div>
            
        {poiK.length > 0 &&
        <div className="container section">
          <div className="row align-items-start">
            <div className="col-12 col-md m-1 did_you_know">
                <h3>{t("Γνωρίζατε ότι")}</h3>
                  <Accordion defaultActiveKey={['-1']}>
                  {poiK.length > 0 &&
                  
                      poiK.map((a1,index ) => (   
                        <Accordion.Item eventKey={index} id={"did_you_know_" + (index+1)}>
                            <Accordion.Header>{a1.title} </Accordion.Header>
                            <Accordion.Body><div id='11'>{a1.description}</div></Accordion.Body>
                          </Accordion.Item>
                        ))
                      }
                    </Accordion>
                  <div>
              </div>
          </div>
      </div>
    </div>  } 
    <br /> <br />
    <div className="container section">
          <div className="row align-items-start">
            <div className="col-12 col-md m-1 did_you_know gal">
                <h3>{t("Άλμπουμ Φωτογραφιών")}</h3>
                <div className="dummy">
                  {poiGallery.map((a4) => (
                      IMAGES.push({title: a4.caption, img: a4.url,imgThumbnail: a4.url })
                  ))}
               </div>
              
              <MuiFbPhotoGrid
                  images={IMAGES}
                  reactModalStyle={{overlay: { zIndex: 2000 }}} 
              />

            </div>

          </div>
        </div>

        <div className="container section">
            <div className="col-12 col-md m-1 did_you_know vid">
                <h3>{t("Βίντεο")}</h3>
                
                <div className="videos">
                <Slider {...settings}>
                  {poiVideo.map((v) => (
                      <div className="singlevideo">
                        <ReactPlayer className='react-player' url={ `${v.url}` }  width='100%'  height='280px'/>
                      </div>
                  ))}
                  </Slider>
               </div>
            </div>
        </div>

        {languageCtx.languageCode === "el" &&
        <div className="container section">
          <div className="row align-items-start">
            <div className="col-12 col-md m-1 did_you_know">
               
                <Accordion defaultActiveKey="-1" id="bibl">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><span>{t("Βιβλιογραφία")}</span></Accordion.Header>
                            <Accordion.Body><div dangerouslySetInnerHTML={ { __html: poi.bibliographic_citation} }></div></Accordion.Body>
                          </Accordion.Item>
                    </Accordion>
             <div>
           </div>
          </div>
      </div>
    </div> }
    </div>
    </div>
    </Fragment>
  )
}