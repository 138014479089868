import React, { Fragment, useState,useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Moment from 'moment';
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";
import AuthContext from "../../store/auth-context";
import Modal from 'react-bootstrap/Modal';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';  
   
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css'; 

import el from 'date-fns/locale/el';
import fr from 'date-fns/locale/fr';
registerLocale('el', el)
registerLocale('fr', fr)
const DeleteTrip = ({uid, tid,name,start_date,end_date}) => {

  const authCtx = useContext(AuthContext);
  const controller = new AbortController();
  const { signal } = controller;
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();
  
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function DelTrip(){
     
   const furl = `https://folegandros.mitos.cityguideplatform.com/api/itinerary?id=${tid}&user_id=${uid}&name=${name}&start_date=${start_date}&end_date=${end_date}&deleted=1`;
console.log(furl)

    const response1 =  await fetch(furl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": '8J5Yymc2pXzPUZnrqqYR',
        },
      })
      if (!response1.ok) {
        throw new Error('Data could not be fetched!')
      } else {
        return response1.json()
    }
  
  }
  return (
    <>

     <div className="abtn11"><span className="addbtn11" onClick={handleShow}><img src="/assets/images/delete.png"/></span></div>
     <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
            <Label htmlFor="confirm" className="leb">{t("Διαγραφή Διαδρομής")}</Label>
              <span classNAme="mbod">{t("Είστε βέβαιοι ότι θέλετε να διαγράψετε τη διαδρομή")} {name}?</span>
            </div>
            <Form  onSubmit={() => { handleClose() }}>  
              <a href="#" className="mbtn1" onClick={handleClose}>{t("Ματαίωση")}</a>                     
              <Button type="submit" value="submit" color="primary" className="mbtn1"  onClick={() => { DelTrip() }}>{t("Διαγραφή")}</Button>
            </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteTrip