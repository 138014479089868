import React, { Component } from 'react';
import Home from './HomeComponent.js';
import RoutesList from './Routes/RoutesComponent.js';
import SingleRoute from './SingleRoute/SingleRouteComponent.js';
import User from './User/User.js';
import Account from './User/Account.js';
import Terms from './PrivacyPolicy/TermsComponent.js';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicyComponent.js';
import Trip from './Trip/TripComponent.js';
import Info from './Info/InfoComponent.js';
import SingleTrip from './Trip/SingleTripComponent.js';
import Intro from './Intro/IntroComponent.js';
import Header from './HeaderComponent.js';
import Footer from './FooterComponent.js';
import { Routes, Route, Navigate } from 'react-router-dom';

class Main extends Component {

  render() {

    return (
      <div>
        <Header />
        <Routes>
           <Route path="/home" element={<Home />} />
           <Route path="/routes" element={<RoutesList />} />
           <Route path="/route/:id" element={<SingleRoute />} />
           <Route path="/user" element={<User />} />
           <Route path="/account" element={<Account />} />
           <Route path="/account/:action" element={<Account />} />
           <Route path="/trip" element={<Trip />} />
           <Route path="/terms" element={<Terms />} />
           <Route path="/privacypolicy" element={<PrivacyPolicy />} />
           <Route path="/introduction" element={<Intro />} />
           <Route path="/information" element={<Info />} />
           <Route path="/trip_id/:id" element={<SingleTrip />} />
           <Route path="/" element={<Navigate replace to="/home" />} />
        </Routes>
      <Footer/>
      </div>
    );
  }
}

export default Main;