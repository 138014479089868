import React, { Fragment,useEffect, useState,useContext } from "react";
import MapAllRoutes from "../MapRoute/MapAllRoutes"
import Affix from "react-simple-affix";

import i18next from "i18next";
import cookies from "js-cookie";
import LanguageContext from "./../../store/language-context";
import { useTranslation } from "react-i18next";

function RoutesList() {
  const [pois, initPois] = useState([])

  const controller = new AbortController();
  const { signal } = controller;

    const languageCtx = useContext(LanguageContext);

    const { t } = useTranslation();
    const fetchData = async () => {

    const pid = window.location.pathname.split("/").pop();
    const furl = `https://folegandros.mitos.cityguideplatform.com/api/pois?lang=${languageCtx.languageCode}`;
      
    const response = await fetch(furl, {signal,
      headers: {
        'X-API-KEY': '8J5Yymc2pXzPUZnrqqYR',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  useEffect(() => {
    
    fetchData()
      .then((res) => {
        initPois(res.success)
        console.log(res.success.extras._content_points.value)
      })
      
      .catch((e) => {
        console.log(e.message)
      })
  },[languageCtx.languageCode])
    return (
      <div>
          <div className="container11 section">
        <div className="row align-items-start equal">
        <div id="side-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
        <Affix
         fixedNavbarSelector="#navbar"
         relativeElementSelector="#main-content"
       >
            
       <div className="sidemap">
       
           <MapAllRoutes />
           </div>
          </Affix>
            </div>
            <div id="main-content" className="col-12 col-sm-12 col-m-6 col-lg-6">
            <h2>{pois.length} {t("Διαδρομές")}  </h2>
            <ul className="routelist">
            {pois.map(poi =>
              <li className="routeitem">
                <div className={`routetag tag${ poi.id }`}> {poi.name} </div>
                <div className="routetitle"> <a href= {"/route/" + poi.id}>{poi.extras._subtitle.value} </a></div>
                
                {languageCtx.languageCode === "el" ? (
                    <div dangerouslySetInnerHTML={ { __html: poi.extras._content_points.value.replace("Κλάδος", "<br />Κλάδος ").replace("Κλάδος1Β", "<br />Κλάδος 1B")} }></div>
                ) : ("")}
                {languageCtx.languageCode === "en" ? (
                    <div dangerouslySetInnerHTML={ { __html: poi.extras._content_points.value.replace("Trail", "<br />Trail ").replace("Trail 1B", "<br />Trail 1B")} }></div>
                ) : ("")}
                {languageCtx.languageCode === "fr" ? (
                    <div dangerouslySetInnerHTML={ { __html: poi.extras._content_points.value.replace("Trail", "<br />Trail ").replace("Trail 1B", "<br />Trail 1B")} }></div>
                ) : ("")}

                <div className="mcolumns measurements">
                { poi.extras._distance.value &&
                <div className="mcolumn">
                  <Fragment> 
                    <div>{t("Απόσταση")} ({t("χλμ")})</div>
                    <span><img src="assets/images/icons-distance.png" alt="folegandros logo" /><div dangerouslySetInnerHTML={ { __html: poi.extras._distance.value.replace(",","<br />").replace(",Κ1Β","<br />Κ1Β")} }></div></span>
                  </Fragment>
                  </div>
                  }
                  { poi.extras._time.value &&
                  <div className="mcolumn">
                  <Fragment> 
                    <div>{t("Χρόνος")} ({t("'Ωρες")})</div>
                    <span><img src="assets/images/icons-alarm-2.png" alt="folegandros logo" /><div dangerouslySetInnerHTML={ { __html: poi.extras._time.value.replaceAll(",","<br />").replaceAll("\"","")} }></div></span>
                  </Fragment>
                  </div>
                   }
                   { poi.extras._difficulty.value &&
                  <div className="mcolumn">
                  <Fragment> 
                    <div>{t("Βαθμίδα")} </div>
                    <span><img src="assets/images/icons-like.png" alt="folegandros logo" /><div dangerouslySetInnerHTML={ { __html: poi.extras._difficulty.value.replace(",","<br />")} }></div></span>
                  </Fragment>
                  </div>
                }
                </div>
              </li>
            )}
            </ul>
          </div>
           
          </div>
        </div>
      </div>
    );
}

export default RoutesList;